<template>
  <div class="basic" v-if="data.weight > 0 && data.muscle > 0 && data.fat > 0 && tdee > 0">

    <v-row dense v-if="!loaded">
      <v-container fluid class="ma-10">
        <v-row dense>
          <v-col cols="12">
            <v-row class="align-center justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-col cols="12" align=center>
                기본 정보를 불러오고 있습니다.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row no-gutters v-if="loaded">
      <v-col cols="12" class="ma-0 pa-3" style="background-color:#141E30;color:white;font-size:30px;font-weight:bold;text-align:center;">
        <span @click="onPressYesterday">&lt;</span>
        {{ date }}
        <span @click="onPressTomorrow">&gt;</span>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="loaded" align="center" justify="center">
      <v-col cols=12>
        <v-card flat class="subtitle-2 pa-4 ma-3 black--text" color="#FBDE44AA">
          <v-row no-gutters>
            <v-col md=10 cols=6>
              <div class="info_title font-weight-bold">섭취할 칼로리</div>
            </v-col>
            <v-col md=2 cols=6 align='right'>
              <span  v-if="diet_status_msg">
                {{ diet_status_msg }}
                <v-btn text xSmall v-if="diet_status_msg == '탄수화물 사이클링'" class="ma-0 pa-0" @click="del_cycling">
                  <v-icon color="red" small class="ma-0 pa-0">delete</v-icon>
                </v-btn>
              </span>
            </v-col>
          </v-row>
          <div class="recommended font-weight-bold" align="center">
            {{ calory.format() }}
            <div style="font-size:14px">{{ tkcal_diff_msg }}</div>
          </div>
          <div class="unit">Kcal</div>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="loaded" align="center" justify="center" >
      <v-col cols="12">
        <v-row no-gutters>
          <v-col sm="8" cols="12">

            <v-card flat tile class="subtitle-2 pa-4 ma-3">
              <v-container fluid v-if="loading" class="ma-10">
                <v-row dense>
                  <v-col cols="12">
                    <v-row class="align-center justify-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <v-col cols="12" align=center>
                        그래프를 불러오고 있습니다.
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              <v-row>
                <v-col cols="12">
                  <div align=center :style="{'float':'left', 'background-color':'#70AD47FF', 'height':'25px', 'width':(data.food.carb.percentage)+'%'}">탄: {{data.food.carb.percentage}} %</div>
                  <div align=center :style="{'float':'left', 'background-color':'#4472C7FF', 'height':'25px', 'width':(data.food.protein.percentage)+'%'}">단: {{data.food.protein.percentage}} %</div>
                  <div align=center :style="{'float':'left', 'background-color':'#ED7D31FF', 'height':'25px', 'width':(data.food.fat.percentage)+'%'}">지: {{data.food.fat.percentage}} %</div>
                  <!-- <canvas id="macro_"></canvas> -->
                </v-col>
                <v-col cols="12">
                  <canvas id="macro2_"></canvas>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col sm="4" cols="12" class="pt-0 pr-3 pl-2 pb-0">

            <v-row class="small subtitle-2">
              <v-col sm="12" cols="6">
                <v-card flat class="small subtitle-2  pt-5 pr-2 pl-2">
                  <v-text-field
                    dense
                    v-model.number="data.food.carb.percentage"
                    class="center-input"
                    :disabled="dietstatus == 3"
                    label="탄수화물 비율"
                    :type="mobile?'number':''"
                    hint="%"
                    persistent-hint
                    v-on:change="changeWantedCarb">
                    <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                      <template v-slot:activator="{ on }">
                        <v-icon color="grey" class="load" v-on="on">mdi-comment-question-outline</v-icon>
                      </template>
                      <span class="caption">
                        <div>50~70%이 좋음</div>
                        <div>탄수화물 : 글리코겐 회복</div>
                        <div>지방 : 포만감, 맛, 지방 에너지원 이용 증가 </div>
                      </span>
                    </v-tooltip>
                  </v-text-field>
                </v-card>
              </v-col>
              <v-col>
                <v-card flat class="small subtitle-2 pt-5 pr-2 pl-2">
                  <v-text-field
                    dense
                    v-model.number="protein_multiplier"
                    class="center-input"
                    label="체중당 섭취할 단백질"
                    :type="mobile?'number':''"
                    required
                    :hint="'g/kg'"
                    persistent-hint
                    v-on:change="changeProteinMultiplier">
                    <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                      <template v-slot:activator="{ on }">
                        <v-icon color="grey" class="load" v-on="on">mdi-comment-question-outline</v-icon>
                      </template>
                      <span class="caption">
                        <div>체지방율 20% 초과의 경우 20%로 환산. (여성의 경우 25%)</div>
                        <div>체중당 섭취할 단백질은 초보자의 경우 2g/kg, 일반적으로 1.6g/kg 권장.</div>
                      </span>
                    </v-tooltip>
                  </v-text-field>
                  <div align=center>
                    <span>권장 단백질 섭취량 : </span>
                    <span style="font-size:1.2em;font-weight:bold;">{{ data.food.protein.gram }}</span>
                    <span>g</span>
                  </div>
                </v-card>
              </v-col>
              <v-col sm="12" cols="6" class="small subtitle-2">
                <v-card flat class="small subtitle-2 pt-5 pr-2 pl-2">
                  <v-text-field
                    dense
                    :disabled="dietstatus > 0"
                    v-model.number="data.calory_delta"
                    class="center-input"
                    label="칼로리 조절"
                    hint="Kcal"
                    persistent-hint
                    v-on:change="changeCaloriesDelta" >
                    <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                      <template v-slot:activator="{ on }">
                        <v-icon color="grey" class="load" v-on="on">mdi-comment-question-outline</v-icon>
                      </template>
                      <span class="caption">
                        <div>린매스업 : +-200Kcal</div>
                        <div>벌크업 : +200~300Kcal</div>
                        <div>다이어트 : -500~700Kcal</div>
                      </span>
                    </v-tooltip>
                  </v-text-field>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-card flat  class="subtitle-2 pa-4 ma-3">
          칼로리 계산은 매크로(탄,단,지)에 각 g당 칼로리를 곱한 것으로 계산합니다.<br/>
          커스텀 음식을 입력할 때 참고 바랍니다.
        </v-card>

        <v-row no-gutters>

          <v-col cols="12" md="9">

            <v-card flat  class="subtitle-2 pa-1 ma-3">

            <v-col md="12" cols="12" v-for="(meal, id) in foods_refined" :key="id" class="ma-0 pa-0">

              <v-card flat class="pa-0 mt-0">
                <v-card-title class="pa-2 pt-0 pb-0 title">
                  <v-row dense>
                    <v-col cols="4">
                      {{ meal.name }}
                    </v-col>
                    <v-col cols="2" class="ma-0 mt-1 pa-0">
                      <div class="subtitle-2 mt-1">
                        {{ Math.round(meal.kcal).format() }}Kcal
                      </div>
                    </v-col>
                    <v-col cols="2" align="center" class="ma-0 mt-1  pa-0">
                      <div class="subtitle-2 mt-1" style="border-bottom: 2px solid #70AD47">
                        {{ (Math.round(meal.carb*10)/10).format() }}g
                      </div>
                    </v-col>
                    <v-col cols="2" align="center" class="ma-0 mt-1  pa-0">
                      <div class="subtitle-2 mt-1" style="border-bottom: 2px solid #4472C7">
                        {{ (Math.round(meal.protein*10)/10).format() }}g
                      </div>
                    </v-col>
                    <v-col cols="2" align="center" class="ma-0 mt-1  pa-0">
                      <div class="subtitle-2 mt-1" style="border-bottom: 2px solid #ED7D31">
                        {{ (Math.round(meal.fat*10)/10).format() }}g
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-row no-gutters>
                  <v-col md="3" cols="12" v-for="(each, i) in meal.list" :key="i" class="ma-0 pa-1">
                    <v-card outlined class="elevation-0 pa-0 ma-0">
                      <v-row dense no-gutters class="pa-1">
                        <v-col cols="12" class="subtitle-2">
                          <v-row dense no-gutters>
                            <v-col cols="10" class="ma-0 pa-0">
                              {{ each.name }}<br/> x {{ each.amount }}
                            </v-col>
                            <v-col cols="2">
                              <v-btn xSmall color="#FBDE44FF" class="elevation-0 op_btn" @click="deleteItem(id, i)" >
                                <v-icon color="black" small>close</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6" class="caption ma-0 pa-0" align="left">{{ each.standard }} {{ each.unit}}</v-col>
                        <v-col cols="6" class="caption ma-0 pa-0" align="right">{{ (Math.round( ( each.carb*each.amount*4 + each.protein*each.amount*4 + each.fat*each.amount*9)*10)/10).format() }}Kcal</v-col>
                        <v-col cols="4" align="right" class="caption ma-0 pa-0" style="border-bottom: 1px solid #70AD47">{{ (Math.round(each.carb*each.amount*10)/10).format() }}g</v-col>
                        <v-col cols="4" align="right" class="caption ma-0 pa-0" style="border-bottom: 1px solid #4472C7">{{ (Math.round(each.protein*each.amount*10)/10).format() }}g</v-col>
                        <v-col cols="4" align="right" class="caption ma-0 pa-0" style="border-bottom: 1px solid #ED7D31">{{ (Math.round(each.fat*each.amount*10)/10).format() }}g</v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card flat class="subtitle-2 pa-1 ma-3">

              <v-dialog
                v-model="dialog_template"
                :fullscreen="mobile" hide-overlay transition="fade-transition"  @click:outside="close_template" width="800">
                <template v-slot:activator="{ on }">
                  <v-btn text small v-on="on" color="#28334AFF" class="elevation-0">
                    <v-icon >assignment_turned_in</v-icon> 식단 템플릿 저장
                  </v-btn>
                </template>

                <v-card class="pa-0 ma-0" style="background-color:#f5f5f5;">

                  <v-toolbar dark color="#FBDE44FF" v-if="mobile" class="mb-5">
                    <v-btn icon color="black" @click="close_template">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title class="black--text">템플릿 추가</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn color="black" text @click="save_template">저장</v-btn>
                    </v-toolbar-items>
                  </v-toolbar>

                  <v-card-text no-gutters class="pb-0">
                    <v-row  no-gutters>
                      <v-col sm="12" cols="12" class="body-2 pa-3 pt-5 pb-1">
                        <div class="font-weight-bold" style="height:25px">안내</div>
                        <div style="line-height:1.8em">
                          - 현재 활동으로 표시되는 내용을 템플릿으로 저장합니다.
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col md="12" cols="12" class="subtitle-2 big2 pa-5 pb-5">
                        <v-text-field
                          v-model="defaultItem_template.name"
                          @input = "change_template_name"
                          label="템플릿명"
                          outlined
                          single-line
                          hide-details
                          style="background-color:white;"
                          required />
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions v-if="!mobile" class="ma-4 mb-0 mt-3 pb-3 pt-0">
                    <v-btn color="secondary" class="white--text" @click="close_template">취소</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="#FBDE44FF" class="black--text" :disabled="!template_selected" @click="save_template">저장</v-btn>
                  </v-card-actions>


                </v-card>
              </v-dialog>
              <v-sheet
                close
                label
                color="#FBDE44FF"
                class="ma-1 pa-2"
                v-for="each in food_templates"
                :key="each._id"
                @click="load_template(each)">
                <v-row no-gutters>
                  <v-col md=6 cols=6 v-if="food_templates_edit_flag[each._id]==''" class="pt-1 pl-1">
                    {{each.name}}
                  </v-col>
                  <v-col md=6 cols=6 v-else>
                    <v-text-field
                      no-gutters
                      class="ma-0 pa-0 edit_input"
                      v-model="food_templates_edit_flag[each._id]"
                      label="템플릿명"
                      single-line
                      hint="엔터키를 치세요"
                      persistent-hint
                      @keyup.enter="confirm_change_template_name(each)"
                    />
                  </v-col>
                  <v-col md=2 cols=2 align='right'>
                    <v-btn text small style="width:30px;" @click.stop.prevent="edit_template_name(each)">
                      <v-icon color="black" small>list</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md=2 cols=2 align='right'>
                    <v-btn text  @click.stop="dialog_template_shared = true; selected_shared_template = each" small style="width:30px;">
                      <v-icon color="blue" small>share</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md=2 cols=2 align='right'>
                    <v-btn text @click.stop.prevent="delete_template(each)" small style="width:30px;">
                      <v-icon color="red" small>cancel</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-card>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <v-row no-gutters class="ma-3">
      <v-col md="4" cols="12">
        <v-card flat class="body-2 pa-2 ma-2">
          <v-card-title class="ma-0 pa-0 mr-4 ml-4 mb-3" style="font-size:15px;font-weight:bold;border-bottom:1px solid #ddd;">
            새로운 식단 템플릿
          </v-card-title>
          <v-card-text>
            <v-row no-gutters v-for="each in shared_templates_latest" :key="each._id">
              <v-col>
                <v-sheet
                  close
                  label
                  class="ma-0 pa-0" >
                  <v-row no-gutters>
                    <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                      <template v-slot:activator="{ on }">
                        <v-col md=10 cols=9 class="pa-1"  v-on="on">
                          <span style="font-weight:500;">{{each.title}}</span> <br/>
                          <span style="font-size:12px;color:#666">{{each.nickname}}</span>
                        </v-col>
                      </template>
                      <span class="caption">
                        {{ each.text }}
                      </span>
                    </v-tooltip>
                    <v-col md=2 cols=3  align='right' >
                      <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                        <template v-slot:activator="{ on }">
                          <v-btn text @click.stop.prevent="import_template(each)" small align="left">
                            <v-icon color="rgb(40, 51, 74)" v-on="on">mdi-import</v-icon>
                          </v-btn>
                        </template>
                        <span class="caption">
                          가져오기
                        </span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4" cols="12">
        <v-card flat class="body-2 pa-2 ma-2">
          <v-card-title class="ma-0 pa-0 mr-4 ml-4 mb-3" style="font-size:15px;font-weight:bold;border-bottom:1px solid #ddd;">
            인기 식단 템플릿
          </v-card-title>
          <v-card-text>
            <v-row no-gutters v-for="each in shared_templates_popular" :key="each._id">
              <v-col>
                <v-sheet
                  close
                  label
                  class="ma-0 pa-0" >
                  <v-row no-gutters>
                    <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                      <template v-slot:activator="{ on }">
                        <v-col md=10 cols=9 class="pa-1"  v-on="on">
                          <span style="font-weight:500;">{{each.title}}</span> <br/>
                          <span style="font-size:12px;color:#666">{{each.nickname}}</span>
                        </v-col>
                      </template>
                      <span class="caption">
                        {{ each.text }}
                      </span>
                    </v-tooltip>
                    <v-col md=2 cols=3  align='right' >
                      <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                        <template v-slot:activator="{ on }">
                          <v-btn text @click.stop.prevent="import_template(each)" small align="left">
                            <v-icon color="rgb(40, 51, 74)" v-on="on">mdi-import</v-icon>
                          </v-btn>
                        </template>
                        <span class="caption">
                          가져오기
                        </span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4" cols="12">
        <v-card flat class="body-2 pa-2 ma-2">
          <v-card-title class="ma-0 pa-0 mr-4 ml-4 mb-3" style="font-size:15px;font-weight:bold;border-bottom:1px solid #ddd;">
            내 공유 식단 템플릿
          </v-card-title>
          <v-card-text>
            <v-row no-gutters v-for="each in shared_templates_mine" :key="each._id">
              <v-col>
                <v-sheet
                  close
                  label
                  class="ma-0 pa-0" >
                  <v-row no-gutters>
                    <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                      <template v-slot:activator="{ on }">
                        <v-col md=10 cols=9 class="pa-1"  v-on="on">
                          <span style="font-weight:500;">{{each.title}}</span> <br/>
                          <span style="font-size:12px;color:#666">{{each.nickname}}</span>
                        </v-col>
                      </template>
                      <span class="caption">
                        {{ each.text }}
                      </span>
                    </v-tooltip>
                    <v-col md=2 cols=3  align='right' >
                      <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                        <template v-slot:activator="{ on }">
                          <v-btn text @click.stop.prevent="unshare_template(each)" small align="left">
                            <v-icon color="red" small v-on="on">share</v-icon>
                          </v-btn>
                        </template>
                        <span class="caption">
                          공유 해제
                        </span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card flat class="body-2 pa-2 ma-3" >
      <v-card-title class="ma-0 pa-0 mr-4 ml-4 mb-3" style="font-size:15px;font-weight:bold;border-bottom:1px solid #ddd;">
        총 공유 활동 템플릿 : {{ shared_template_count }} 개
      </v-card-title>
      <v-card-text>
        <v-row no-gutters >
          <v-col md="3" cols="12">
            <v-row  no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="search_template_name"
                  append-icon="search"
                  label="공유 템플릿 검색"
                  outlined
                  single-line
                  hide-details
                  class="dialog_search"
                  @click:append="search_template"
                  @keyup.enter="search_template"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="shared_templates_searched.length > 0">
              <v-col>
                검색된 템플릿 : {{ shared_templates_searched.length }} 개
              </v-col>
            </v-row>
          </v-col>
          <v-col md="9" cols="12" class="pa-0 pr-0 pl-1">
            <v-row no-gutters v-for="each in shared_templates_searched" :key="each._id">
              <v-col>
                <v-sheet
                  close
                  label
                  class="ma-1 mt-0 mb-2 pa-1" style="border:1px solid #dddddd; border-radius:5px;">
                  <v-row no-gutters>
                    <v-col md=10 cols=9 class="pa-1">
                      <span style="font-weight:500;font-size:1.2em;">{{each.title}}</span> <br/>
                      <span style="font-size:12px;color:#666">{{each.nickname}}</span>
                    </v-col>
                    <v-col md=2 cols=3  align='right' >
                      <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                        <template v-slot:activator="{ on }">
                          <v-btn text @click.stop.prevent="import_template(each)" small align="left">
                            <v-icon color="rgb(40, 51, 74)" v-on="on">mdi-import</v-icon>
                          </v-btn>
                        </template>
                        <span class="caption">
                          가져오기
                        </span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="pa-1" style="border-top:1px solid #efefef" v-html="each.text.split('\n').join('<br />')">

                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-dialog
      v-model="dialog"
      :fullscreen="mobile"
      hide-overlay
      transition="fade-transition"
      @click:outside="close"
      style="overflow:auto;-webkit-overflow-scrolling:touch;"
      width="800">
      <template v-slot:activator="{ on }">
        <v-btn class="mx-3 ma-3" color="black" fab v-on="on" elevation=0 :class="{mobile_input:mobile, desktop_input:!mobile}">
          <v-icon color="#FBDE44FF" x-large>add</v-icon>
        </v-btn>
      </template>
      <v-card class="pa-0 ma-0" style="background-color:#f5f5f5;">

        <v-toolbar dark color="#FBDE44FF" v-if="mobile" class="mb-5">
          <v-btn icon color="black" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="black--text">식단 추가</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="black" text :disabled="!food_selected || !editedItem.amount || !editedItem.meal"  @click="save2">저장 후 추가</v-btn>
            <!--<v-btn color="black" text @click="save">저장</v-btn>-->
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text no-gutters class="pb-0 pt-5">

          <template class="overflow-y-auto" v-if="editedIndex">
            <v-card flat style="background-color:#f5f5f5;">
              <v-tabs v-model="food_tab">
                <v-tab :key="list">
                  식품
                </v-tab>
                <v-tab :key="manual">
                  수동입력
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="food_tab">
                <v-tab-item :key="list">
                  <v-row no-gutters>
                    <v-col cols="12" class="pt-5 pb-5">
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="검색"
                        outlined
                        single-line
                        hide-details
                        class="dialog_search"
                        style="background-color:white;"
                      ></v-text-field>
                    </v-col>

                    <v-col sm="8" cols="12" style="background-color:white;border:1px solid #dddddd">
                      <v-data-table
                        class="dialog_table mt-5"
                        :headers="rawheaders"
                        :items="rawfoods"
                        :search="search"
                        :custom-filter="customSearch"
                        :items-per-page=5
                        :mobile-breakpoint=NaN
                        dense
                        no-data-text="목록이 없습니다."
                        no-results-text="검색 결과가 없습니다."
                        :footer-props="{
                          itemsPerPageText: ''
                        }"
                      >
                        <template v-slot:item="{ item }">
                          <tr @click="selectRawFood(item)">
                            <td>
                              <v-icon v-if="item.custom" color="red" small>star</v-icon>
                              {{item.name}}
                            </td>
                            <td align=right>{{(item.standard === undefined ) ? 100: item.standard}} {{item.unit}}</td>
                            <td align=right>{{item.carb}}</td>
                            <td align=right>{{item.protein}}</td>
                            <td align=right>{{item.fat}}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>

                    <v-col sm="4" cols="12" class="ma-0 pa-0">
                      <v-row no-gutters>

                        <v-col sm="12" cols="12"  class="subtitle-2">
                          <v-card outlined :class="{ 'elevation-0':1, 'pa-3':1, 'pt-1': 1, 'pb-2': 1, 'mb-2': !mobile, 'ml-2': !mobile, 'mt-2': mobile }">
                            <v-row dense>
                              <v-col cols="12" class="subtitle-2">
                                <v-row dense>
                                  <v-col cols="12">
                                    {{ editedItem.name?editedItem.name:"식품명" }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #70AD47">
                                {{ editedItem.carb.format() }}g
                              </v-col>
                              <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #4472C7">
                                {{ editedItem.protein.format() }}g
                              </v-col>
                              <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #ED7D31">
                                {{ editedItem.fat.format() }}g
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>

                        <v-col sm="12" cols="6" class="subtitle-2 big2">
                          <v-text-field
                            style="background-color:white;border:1px solid #dddddd"
                            v-model.number="editedItem.amount"
                            :disabled="!food_selected"
                            :class="{ 'center-input':1, 'pa-3':1, 'pt-6': 1, 'pb-1': 1, 'mb-2': !mobile, 'ml-2': !mobile, 'mt-2': mobile, 'mr-1': mobile }"
                            label="수량"
                            :type="mobile?'number':''"
                            required
                            :hint="((editedItem.standard !== undefined) ? editedItem.standard : 100 ) + (editedItem.unit?editedItem.unit:'g') + ' 기준'"
                            persistent-hint />
                        </v-col>

                        <v-col sm="12" cols="6" class="subtitle-2 big2">
                          <v-select
                            style="background-color:white;border:1px solid #dddddd"
                            :items="meals"
                            v-model="editedItem.meal"
                            :disabled="!food_selected"
                            :class="{ 'right-input':1, 'pa-3':1, 'pt-6': 1, 'pb-1': 1, 'mb-0': !mobile, 'ml-2': !mobile, 'mt-2': mobile, 'ml-1': mobile }"
                            label="식사 구분"
                            required
                            v-on:change="changeMeal" />
                        </v-col>

                      </v-row>

                    </v-col>

                  </v-row>

                  <v-row no-gutters class="mt-2">

                    <v-col sm="12" cols="12" class="body-2 pa-3 pt-1 pb-1">
                      <div class="font-weight-bold" style="height:25px">안내</div>
                      <div style="line-height:1.8em">
                        - 표에서 음식을 선택하고 수량 및 시간을 입력하세요.<br/>
                        - 100g, 100ml단위일 경우 180g, 180ml를 넣고 싶다면 수량을 1.8로 입력하시면 됩니다.<br/>
                        - 커스텀 음식 <v-icon color="red" small>star</v-icon><span class="caption"> (마이페이지 > 커스텀 음식에서 입력 가능합니다.)</span>
                      </div>
                    </v-col>

                  </v-row>
                </v-tab-item>
                <v-tab-item :key='manual'>
                  <v-row no-gutters>
                    <v-col sm="8" cols="12" class="subtitle-2 big2 mt-5">
                      <v-text-field
                        style="background-color:white;border:1px solid #dddddd"
                        v-model="editedItemManual.name"
                        :class="{ 'center-input':1, 'pa-3':1, 'pt-6': 1, 'pb-1': 1, 'mb-2': !mobile, 'mt-2': mobile, 'mr-1': mobile }"
                        label="음식명"
                        required
                        persistent-hint />
                    </v-col>
                    <v-col sm="4" cols="6" class="subtitle-2 big2 mt-5">
                      <v-select
                        style="background-color:white;border:1px solid #dddddd"
                        :items="meals"
                        v-model="editedItemManual.meal"
                        :class="{ 'right-input':1, 'pa-3':1, 'pt-6': 1, 'pb-1': 1, 'mb-0': !mobile, 'ml-2': !mobile, 'mt-2': mobile, 'ml-1': mobile }"
                        label="식사 구분"
                        required
                        v-on:change="changeMealManual" />
                    </v-col>
                    <v-col sm="4" cols="12" class="subtitle-2 big2">
                      <v-text-field
                        style="background-color:white;border:1px solid #dddddd"
                        v-model.number="editedItemManual.carb"
                        :class="{ 'center-input':1, 'pa-3':1, 'pt-6': 1, 'pb-1': 1, 'mb-2': !mobile, 'mt-2': mobile, 'mr-2': 1 }"
                        label="탄수화물"
                        :type="mobile?'number':''"
                        required
                        persistent-hint />
                    </v-col>
                    <v-col sm="4" cols="12" class="subtitle-2 big2">
                      <v-text-field
                        style="background-color:white;border:1px solid #dddddd"
                        v-model.number="editedItemManual.protein"
                        :class="{ 'center-input':1, 'pa-3':1, 'pt-6': 1, 'pb-1': 1, 'mb-2': !mobile, 'mt-2': mobile, 'mr-1': mobile }"
                        label="단백질"
                        :type="mobile?'number':''"
                        required
                        persistent-hint />
                    </v-col>
                    <v-col sm="4" cols="12" class="subtitle-2 big2">
                      <v-text-field
                        style="background-color:white;border:1px solid #dddddd"
                        v-model.number="editedItemManual.fat"
                        :class="{ 'center-input':1, 'pa-3':1, 'pt-6': 1, 'pb-1': 1, 'mb-2': !mobile, 'mt-2': mobile, 'ml-2': 1 }"
                        label="지방"
                        :type="mobile?'number':''"
                        required
                        persistent-hint />
                    </v-col>
                  </v-row>

                  <v-row no-gutters class="mt-2">

                    <v-col sm="12" cols="12" class="body-2 pa-3 pt-1 pb-1">
                      <div class="font-weight-bold" style="height:25px">안내</div>
                      <div style="line-height:1.8em">
                        - 탄단지를 입력하세요.<br/>
                        - 칼로리는 탄단지를 기반으로 계산됩니다. 탄단지 기반 통계를 제공하기 때문에 칼로리 입력은 지원하지 않습니다.
                      </div>
                    </v-col>

                  </v-row>
                </v-tab-item>
              </v-tabs-items>

            </v-card>
          </template>

        </v-card-text>

        <v-tabs-items v-model="food_tab">

          <v-tab-item :key='list'>
            <v-card-actions v-if="!mobile" class="ma-4 mb-0 mt-3 pb-3 pt-0">
              <v-btn color="secondary" class="white--text" @click="close">닫기</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="#FBDE44FF" class="black--text" :disabled="!food_selected || !editedItem.amount || !editedItem.meal" @click="save2">저장 후 추가</v-btn>
              <!--<v-btn color="#FBDE44FF" class="black--text" @click="save">저장 후 닫기</v-btn>-->
            </v-card-actions>
          </v-tab-item>
          <v-tab-item :key='manual'>
            <v-card-actions v-if="!mobile" class="ma-4 mb-0 mt-3 pb-3 pt-0">
              <v-btn color="secondary" class="white--text" @click="close">닫기</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="#FBDE44FF" class="black--text" @click="save_manual_food">저장 후 추가</v-btn>
            </v-card-actions>
          </v-tab-item>

        </v-tabs-items>

      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_template_shared"
      :fullscreen="mobile"
      hide-overlay transition="fade-transition"
      @click:outside="close_template_shared"
      width="800">
      <v-card class="pa-0 ma-0"  style="background-color:#f5f5f5;">

        <v-toolbar dark color="#FBDE44FF" v-if="mobile" class="mb-5">
          <v-btn icon color="black" @click="close_template_shared">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="black--text">템플릿 공유</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="black" text @click="share_template">공유</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text no-gutters class="pb-0">
          <v-row  no-gutters>
            <v-col sm="12" cols="12" class="body-2 pa-3 pt-5 pb-1">
              <div class="font-weight-bold" style="height:25px">안내</div>
              <div style="line-height:1.8em">
                - 현재 템플릿을 공유합니다.
              </div>
            </v-col>
          </v-row>
          <v-row  no-gutters>
            <v-col md="12" cols="12" class="subtitle-2 pt-5 pb-5">
              <v-text-field
                v-model="defaultItem_template_shared.name"
                @input = "change_template_shared_name"
                label="템플릿 공유 이름"
                outlined
                single-line
                hide-details
                style="background-color:white;"
                required />
            </v-col>
          </v-row>
          <v-row  no-gutters>
            <v-col md="12" cols="12" class="subtitle-2 pt-5 pb-5">
              <v-textarea
                v-model="defaultItem_template_shared.text"
                @input = "change_template_shared_text"
                label="템플릿 공유 설명"
                outlined
                hide-details
                style="background-color:white;"
                required />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions v-if="!mobile"  class="ma-4 mb-0 mt-3 pb-3 pt-0">
          <v-btn color="secondary" class="white--text" @click="close_template_shared">취소</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="#FBDE44FF" class="black--text" :disabled="!template_selected" @click="share_template">공유</v-btn>
        </v-card-actions>


      </v-card>
    </v-dialog>

  </div>
  <div v-else>
    <v-card flat class="pa-4 ma-3">
      기본 정보 및 활동 정보를 먼저 입력하세요.
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';
import {
  sleep,
  calc_food,
  calc_protein,
  calc_food_protein,
  get_custom_food,
} from '../../common/functions.js';

import { mapGetters } from 'vuex';

let week = ['일', '월', '화', '수', '목', '금', '토'];

export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      food_tab: 'list',
      protein_multiplier: 1.6,
      calory: 0,
      loading: true,

      // 식단 템플릿 공유 관련
      shared_template_count: 0,
      shared_templates: [],
      shared_templates_mine: [],
      shared_templates_latest: [],
      shared_templates_popular: [],
      search_template_name:"",
      shared_templates_searched: [],
      template_shared_selected : false,
      selected_shared_template : {},
      dialog_template_shared: false,
      defaultItem_template_shared: {
        name: '',
        text: '',
      },

      //
      date_picker_layout: false,

      food_selected: false,
      template_selected: false,

      show_desc: false,
      calory_guide: "",
      chart: null,
      chart2: null,
      showdesc_carb: false,

      foods_refined: {
        breakfast: {
          name: '아침',
          list: [],
          kcal: 0,
          carb: 0,
          protein: 0,
          fat: 0,
        },
        lunch: {
          name: '점심',
          list: [],
          kcal: 0,
          carb: 0,
          protein: 0,
          fat: 0,
        },
        dinner: {
          name: '저녁',
          list: [],
          kcal: 0,
          carb: 0,
          protein: 0,
          fat: 0,
        },
        snack: {
          name: '간식',
          list: [],
          kcal: 0,
          carb: 0,
          protein: 0,
          fat: 0,
        },
      },

      foods: [],
      search: "",
      dialog: false,
      headers: [
        { text: '구분', sortable: true, value: 'meal', },
        { text: '음식', align: 'left', sortable: false, value: 'name', },
        { text: '섭취량', value: 'amount' },
        //{ text: '칼로리 Kcal', value: 'calory' },
        { text: '탄 g', value: 'carb', sortable: false },
        { text: '단 g', value: 'protein', sortable: false },
        { text: '지 g', value: 'fat', sortable: false },
        { text: '', value: 'action', sortable: false, width:70 },
      ],
      rawheaders: [
        {
          text: '음식',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        //{ text: '칼로리 Kcal', value: 'calory', sortable: false, },
        { text: '기준량', align: 'right', value: 'standard', sortable: false, width: 100, },
        { text: '탄 g', align: 'right',value: 'carb', sortable: false, width: 60, },
        { text: '단 g', align: 'right',value: 'protein', sortable: false, width: 60, },
        { text: '지 g', align: 'right',value: 'fat', sortable: false, width: 60, },
      ],
      rawfoods: [],
      meals: [
        { text: '아침', value: 1, },
        { text: '점심', value: 2, },
        { text: '저녁', value: 3, },
        { text: '간식', value: 4, },
      ],
      selected_meal: 0,
      isEdit: false,
      editedIndex: -1,
      selectedItem: {},
      editedItem: {
        name: '',
        meal: 1,
        amount: 1,
        fat: 0,
        protein: 0,
        carb: 0,
        calory: 0,
        category: '',
        name_search: '',
      },
      editedItemManual: {
        name: '',
        protein:0,
        carb:0,
        fat:0,
        meal: 1,
        amount: 1,
        unit: '인분',
        standard: 1,
      },
      defaultItem: {
        name: '',
        meal: '',
        amount: 1,
        fat: 0,
        protein: 0,
        carb: 0,
        calory: 0,
        category: '',
        name_search: '',
      },
      defaultItemManual: {
        name: '',
        protein:0,
        carb:0,
        fat:0,
        meal: 1,
        amount: 1,
        unit: '인분',
        standard: 1,
      },
      draw_chart: null,
      dialog_template: false,
      defaultItem_template: {
        name: '',
      },
      food_templates: [],
      diet_status_msg: "",
      is_cheating: false,
      tkcal_diff_msg: "",
      hour_disabled: false,
      is_activity: false,


      food_templates_edit_flag: {},
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      data: "data",
      mobile: "mobile",
      loaded: "loaded",
      foodlist: "foodlist",
      dietstatus: "dietstatus",
      activity: "activity",

      date: "date",
      base_calory: "base_calory",
      tdee: "tdee",
    }),
    getLoaded: function() {
      return this.$store.getters.loaded;
    },
    formTitle () {
      return this.editedIndex === -1 ? '추가' : '수정'
    },
    getCloryCalculated: function() {
      return this.$store.getters.data.calory_calculated;
    },
    getProtein: function() {
      return this.$store.getters.data.food.protein.gram;
    },
    getDate: function() {
      return this.$store.getters.date;
    },
    getTDEE: function() {
      return this.$store.getters.tdee;
    },
    getFood: function() {
      return this.$store.getters.data.food;
    },
    getActivity: function() {
      return this.$store.getters.activity;
    },
    // getCaloryDelta: function() {
    //   return this.$store.getters.data.calory_delta;
    // }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    getLoaded: async function(v, oldv) {
      if ( v == true ) {
        await this.reload_init();
      }
    },
    getCloryCalculated: async function(v, oldv) {
      await this.reload_info();
      await this.draw_graph();
    },
    getProtein: async function(v, oldv) {
      await this.calc_macro_info();//await this.reload_info();
    },
    getTDEE: async function(v, oldv) {
      await this.reload_init();
    },
    getDate: async function(v, oldv) {
      if ( this.loaded ) {
        await this.reload_init();
      }
    },
    getFood: async function(v, oldv) {
      await this.calc_macro_info();
    },
    getActivity: async function(v, oldv) {
      // console.log(v)
    }
    // getCaloryDelta: async function(v, oldv) {
    //   if ( Math.abs(v) > 1000 ) {
    //     alert('-1000 ~ 1000사이 값을 입력하세요.')
    //     return;
    //   }

    //   await this.reload_info();
    //   await this.draw_graph();
    // }
  },
  mounted: async function() {

      await this.reload_init();
      await this.build_template_flag();

      await this.list_template_shared();
      await this.list_template_shared_mine();
      await this.list_template_shared_popular();

      await this.list_template_shared_latest();
      setTimeout(async ()=>{
        await this.list_template_shared_latest();
      }, 10000);

  },
  methods: {
    reload_init: async function() {

      this.protein_multiplier = this.data.protein_multiplier;

      await this.get_info();
      await this.reload_info();
      await this.get_templates();

      await this.calc_diet_period();

      await this.calc_macro_info();

      await this.draw_graph();

      this.loading = false;
    },
    get_templates: async function() {
      const id_token = await firebase.auth().currentUser.getIdToken();
      const templates = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food_template', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });
      if (templates.data.length>0) {
        this.food_templates = templates.data;
      }
    },

    build_template_flag : async function() {
      for ( let each of this.food_templates ) {
        this.$set(this.food_templates_edit_flag, each._id, '');
      }
    },

    change_template_name: function() {
      if ( this.defaultItem_template.name == "" ) {
        this.template_selected = false;
      } else {
        this.template_selected = true;
      }
    },

    close_template: function () {
      this.dialog_template = false;
      this.defaultItem_template.name = "";
    },


    close_template_shared:  function() {
      this.dialog_template_shared = false;
      this.defaultItem_template_shared.name = "";
      this.defaultItem_template_shared.text = "";
      this.template_shared_selected = false;
      this.selected_shared_template = {};
    },

    save_template: async function () {
      if ( this.defaultItem_template.name == "" ) {
        alert("템플릿명이 필요합니다.");
        return false;
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food_template', {
        email: this.user.data.email,
        name: this.defaultItem_template.name,
        foodlist: this.foods,
      }, {
        headers: {'id_token': id_token},
      });

      this.get_templates();

      this.reload_info();

      this.close_template();
    },

    delete_template: async function(item) {
      const index = this.food_templates.indexOf(item)
      confirm('삭제하시겠습니까?') && this.food_templates.splice(index, 1);
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food_template/', {
          data: {
            email: this.user.data.email,
            template_id: item._id,
          },
          headers: {'id_token': id_token},
      });
      this.reload_info();
    },


    edit_template_name: async function(item) {

      if ( this.food_templates_edit_flag[item._id] == '' )
        this.$set(this.food_templates_edit_flag, item._id, item.name);
      else
        this.$set(this.food_templates_edit_flag, item._id, '');

    },


    load_template: async function(item) {
      this.foods = item.foodlist;
      await this.reload_food_list(this.foods);

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/foodhistory', {
        email: this.user.data.email,
        date: this.date,
        foodlist: this.foods,
      }, {
        headers: {'id_token': id_token},
      });

      await this.$store.dispatch("fetchFoodlist", this.foods);

      this.reload_info();
      await this.draw_graph();
    },
    get_info: async function(dietstatus) {

      const id_token = await firebase.auth().currentUser.getIdToken();
      const foodmacro = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/foodmacro', {
        params: { email: this.user.data.email, date: this.date },
        headers: {'id_token': id_token},
      });

      if ( foodmacro.data.length>0 ) {
        let latest_ = foodmacro.data[foodmacro.data.length-1];
        let macroinfo_ = {
          carb: {
            percentage: 0,
            kcal: 0,
            gram: 0,
          },
          protein: {
            percentage: 0,
            kcal: 0,
            gram: 0,
          },
          fat: {
            percentage: 0,
            kcal: 0,
            gram: 0,
          },
        };
        macroinfo_.carb.percentage = latest_.carb_percentage;
        macroinfo_.carb.kcal = latest_.carb_kcal;
        macroinfo_.carb.gram = latest_.carb_gram;
        macroinfo_.protein.percentage = latest_.protein_percentage;
        macroinfo_.protein.kcal = latest_.protein_kcal;
        macroinfo_.protein.gram = latest_.protein_gram;
        macroinfo_.fat.percentage = latest_.fat_percentage;
        macroinfo_.fat.kcal = latest_.fat_kcal;
        macroinfo_.fat.gram = latest_.fat_gram;

        await this.$store.dispatch("fetchFood", macroinfo_);
      }

      // 식품 목록 가져오기 ////////////////////////////////////
      const raw_foods = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      if (raw_foods.data.length>0) {
        this.rawfoods = raw_foods.data;
      }

      // 식단 히스토리 조회 ////////////////////////////////////

      const today = this.date; // this.$moment().format("YYYY-MM-DD");

      const food_history = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/foodhistory', {
        params: { email: this.user.data.email, date: today },
        headers: {'id_token': id_token},
      });

      this.foods = [];
      if (food_history.data.length>0) {
        let latest_food_history = food_history.data[food_history.data.length-1];
        this.$store.dispatch("fetchFoodlist", latest_food_history.foodlist);
        this.reload_food_list(latest_food_history.foodlist);
      } else {
        this.$store.dispatch("fetchFoodlist", []);
        this.reload_food_list([]);
      }

      this.foods = this.foodlist;
      ///////////////////////////////////////////////////////////////

      //// 커스텀 푸드
      const custom_food = await get_custom_food(this.user.data.email, 0);
      if (custom_food.length>0) {
        this.rawfoods.unshift(...custom_food);
      }
      ////

    },
    reload_food_list: async function(v) {

      for ( let [key, meal] of Object.entries(this.foods_refined) ) {
        this.foods_refined[""+key+""].list = [];
        this.foods_refined[""+key+""].kcal = 0;
        this.foods_refined[""+key+""].carb = 0;
        this.foods_refined[""+key+""].protein = 0;
        this.foods_refined[""+key+""].fat = 0;
      }

      let arr = ['t', 'breakfast', 'lunch', 'dinner', 'snack'];

      for ( let each of v ) {
        this.foods_refined[arr[each.meal]].list.push(each);
        this.foods_refined[arr[each.meal]].carb += each.carb * each.amount;
        this.foods_refined[arr[each.meal]].protein += each.protein * each.amount;
        this.foods_refined[arr[each.meal]].fat += each.fat * each.amount;
        this.foods_refined[arr[each.meal]].kcal +=
          each.carb * each.amount * 4 +
          each.protein * each.amount * 4 +
          each.fat * each.amount * 9;
      }
    },
    calc_diet_period: async function() {
      if ( this.data.weight_history[0] !== undefined ) {

        let now = this.$moment();
        let start = this.$moment(this.data.weight_history[0].date);

        let week_passed = now.diff(start, 'weeks');

        this.calory_guide = week_passed+"주 경과, +-"+(week_passed*50)+" Kcal 추천"
      }

    },
    draw_graph: async function() {

      if ( this.chart !== null )
        this.chart.destroy();

      if ( this.chart2 !== null )
        this.chart2.destroy();

      let data_ = [
        this.data.food.carb.percentage,
        this.data.food.protein.percentage,
        this.data.food.fat.percentage
      ];

      // if ( document.getElementById('macro_') == null ) return;

      // const chart_ctx = document.getElementById('macro_').getContext('2d');
      // this.chart = new this.$Chart(chart_ctx, {
      //   type: 'doughnut',
      //   data: {
      //     labels: [
      //       "탄",
      //       "단",
      //       "지",
      //     ],
      //     datasets: [
      //       {
      //         data: data_,
      //         fill: true,
      //         borderWidth: 1,
      //         borderColor: ["#70AD47FF", "#4472C7FF", "#ED7D31FF"],
      //         backgroundColor: ["#70AD47CC", "#4472C7CC", "#ED7D31CC"],
      //       },
      //     ],
      //   },
      //   options: {
      //     animation: {
      //       duration: 0,
      //     },
      //     responsive: true,
      //     maintainAspectRatio: false, // default value. false일 경우 포함된 div의 크기에 맞춰서 그려짐.
      //     legend: {
      //       position: 'top',
      //       labels: {
      //         boxWidth: 20,
      //       }
      //     },
      //     plugins: {
      //       datalabels: {
      //         color: 'black',
      //         align: 'center',
      //         formatter: function(value, context) {
      //           return Number(value).format()+ "%";
      //         }
      //       }
      //     },
      //   },
      // });

      let breakfast = [0,0,0];
      let lunch = [0,0,0];
      let dinner = [0,0,0];
      let snack = [0,0,0];

      for ( let each of this.foodlist ) {
        if ( each.meal == 1 ) {
          breakfast[0] += Number(each.carb*4*each.amount);
          breakfast[1] += Number(each.protein*4*each.amount);
          breakfast[2] += Number(each.fat*9*each.amount);
        } else if ( each.meal == 2 ) {
          lunch[0] += Number(each.carb*4*each.amount);
          lunch[1] += Number(each.protein*4*each.amount);
          lunch[2] += Number(each.fat*9*each.amount);
        } else if ( each.meal == 3 ) {
          dinner[0] += Number(each.carb*4*each.amount);
          dinner[1] += Number(each.protein*4*each.amount);
          dinner[2] += Number(each.fat*9*each.amount);
        } else if ( each.meal == 4 ) {
          snack[0] += Number(each.carb*4*each.amount);
          snack[1] += Number(each.protein*4*each.amount);
          snack[2] += Number(each.fat*9*each.amount);
        }
      }

      // breakfast[0] = Math.round(breakfast[0]/10)*10;
      // breakfast[1] = Math.round(breakfast[1]/10)*10;
      // breakfast[2] = Math.round(breakfast[2]/10)*10;
      // lunch[0] = Math.round(lunch[0]/10)*10;
      // lunch[1] = Math.round(lunch[1]/10)*10;
      // lunch[2] = Math.round(lunch[2]/10)*10;
      // dinner[0] = Math.round(dinner[0]/10)*10;
      // dinner[1] = Math.round(dinner[1]/10)*10;
      // dinner[2] = Math.round(dinner[2]/10)*10;
      // snack[0] = Math.round(snack[0]/10)*10;
      // snack[1] = Math.round(snack[1]/10)*10;
      // snack[2] = Math.round(snack[2]/10)*10;


      //snack[1] += Number(Number(this.data.dietary.proteinfromrice) + Number(this.data.dietary.proteinafterworkout*4));

      let total = [
        Math.round(this.data.food.carb.kcal/10)*10,
        Math.round(this.data.food.protein.kcal/10)*10,
        Math.round(this.data.food.fat.kcal/10)*10,
      ];

      function getMaxNumberFromArray(array) {
        return array.reduce((a, b) => Math.max(a, b));
      }

      const maxYTick = Math.max(
        getMaxNumberFromArray(total),
      );

      let t2_ = breakfast[0] + breakfast[1] + breakfast[2] +
        lunch[0] + lunch[1] + lunch[2] +
        dinner[0] + dinner[1] + dinner[2] +
        snack[0] + snack[1] + snack[2];

      this.tkcal_diff_msg = " (" + Math.round((Number( this.calory - t2_ )/10)*10).format();

      if ( (this.calory - t2_) > 0 ) {
        this.tkcal_diff_msg += "kcal 부족) ";
      } else {
        this.tkcal_diff_msg += "kcal 초과) ";
      }

      let option = {
        type: 'bar',
        data: {
          labels: [
            "탄수화물",
            "단백질",
            "지방",
          ],
          datasets: [
            {
              label: "아침",
              data: breakfast,
              barThickness: (this.mobile) ? 50 :70,
              borderWidth: 1,
              backgroundColor: ["#448A16AA", "#1048B1AA", "#ED7D31AA"],
              yAxisID: '2',
            },
            {
              label: "점심",
              data: lunch,
              barThickness: (this.mobile) ? 50 :70,
              borderWidth: 1,
              backgroundColor: ["#70AD47AA", "#4472C7AA", "#F2A46EAA"],
              yAxisID: '2',
            },
            {
              label: "저녁",
              data: dinner,
              barThickness: (this.mobile) ? 50 :70,
              borderWidth: 1,
              backgroundColor: ["#B1D698AA", "#82A1DCAA", "#F8CCAFAA"],
              yAxisID: '2',
            },
            {
              label: "간식",
              data: snack,
              barThickness: (this.mobile) ? 50 :70,
              borderWidth: 1,
              backgroundColor: ["#F3F9EFAA", "#CAD8F1AA", "#FDF7F2AA"],
              yAxisID: '2',
            },
            {
              label: "전체",
              data: total,
              fill: true,
              barThickness: (this.mobile) ? 60 :85,
              borderWidth: 1,
              backgroundColor: ["#DDDDDDAA", "#DDDDDDAA", "#DDDDDDAA"],
              yAxisID: '1',
            },
          ],
        },
        options: {
          animation: {
            duration: 0,
          },
          responsive: true,
          maintainAspectRatio: false, // default value. false일 경우 포함된 div의 크기에 맞춰서 그려짐.
          scales: {
            xAxes: [{
              display: true,
              stacked: true,
              ticks: {
                beginAtZero:true,
                //fontColor: 'white',
              },
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              display: false,
              stacked: true,
              beginAtZero: true,
            },{
              id: "1",
              display: true,
              stacked: true,
              beginAtZero: true,
              ticks: {
                max: Math.round((maxYTick+1000)/1000)*1000,
                min: 0,
                callback: function(value, index, values) {
                  return Number(value).format();
                }
                //fontColor: 'white',
              },
              scaleLabel: {
                display: true,
                labelString: 'Kcal',
                //fontColor: 'white',
              }
            },{
              id: "2",
              display: false,
              position: "right",
              stacked: true,
              stepSize: 100,
              beginAtZero: true,
              ticks: {
                max: Math.round((maxYTick+1000)/1000)*1000,
                min: 0,
                callback: function(value, index, values) {
                  return Number(value).format();
                }
                //fontColor: 'white',
              }
            }],
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                if ( tooltipItem.index == 0 ) {
                  return data.datasets[tooltipItem.datasetIndex].label+ " : " + tooltipItem.value + "Kcal, " + Number(Number(tooltipItem.value/4).toFixed(1)) + " g";
                } else if ( tooltipItem.index == 1 ) {
                  return data.datasets[tooltipItem.datasetIndex].label+ " : " + tooltipItem.value + "Kcal, " + Number(Number(tooltipItem.value/4).toFixed(1)) + " g";
                } else if ( tooltipItem.index == 2 ) {
                  return data.datasets[tooltipItem.datasetIndex].label+ " : " + tooltipItem.value + "Kcal, " + Number(Number(tooltipItem.value/9).toFixed(1)) + " g";
                }
              }
            }
          },
          legend: {
            display: false,
            labels: {
              fontColor: 'white',
            }
          },
          plugins: {
            datalabels: {
              //display: 'auto',
              anchor: function(c) {
                if ( c.datasetIndex == 4 ) {
                  return "end";
                } else if ( c.datasetIndex == 0 || c.datasetIndex == 2 ) {
                  return "center";
                } else if ( c.datasetIndex == 1 || c.datasetIndex == 3 ) {
                  return "center";
                }
              },
              color: 'black',
              align: function(c) {
                if ( c.datasetIndex == 4 ) {
                  return "top";
                } else if ( c.datasetIndex == 0 || c.datasetIndex == 2 ) {
                  return "left";
                } else if ( c.datasetIndex == 1 || c.datasetIndex == 3 ) {
                  return "right";
                }

              },
              offset: function(c) {
                if ( c.datasetIndex == 4 ) {
                  return -10;
                } else if ( c.datasetIndex == 0 || c.datasetIndex == 2 ) {
                  return 10;
                } else if ( c.datasetIndex == 1 || c.datasetIndex == 3 ) {
                  return 10;
                }

              },
              font: {
                size: "10",
              },
              textAlign: "right",
              formatter: function(value, c) {
                if ( value > 0 ) {
                  if ( c.datasetIndex == 4 ) {
                    let t_ = "";
                    if ( snack[c.dataIndex] > 0 )
                      t_ += "간식 : " + Math.round((Number(snack[c.dataIndex])/10)*10).format() + " \r\n";
                    if ( dinner[c.dataIndex] > 0 )
                      t_ += "저녁 : " + Math.round((Number(dinner[c.dataIndex])/10)*10).format() + " \r\n";
                    if ( lunch[c.dataIndex] > 0 )
                      t_ += "점심 : " + Math.round((Number(lunch[c.dataIndex])/10)*10).format() + " \r\n";
                    if ( breakfast[c.dataIndex] > 0 )
                      t_ +=  "아침 : " + Math.round((Number(breakfast[c.dataIndex])/10)*10).format() + " \r\n";
                    t_ += Math.round((Number(breakfast[c.dataIndex] + lunch[c.dataIndex] + dinner[c.dataIndex] + snack[c.dataIndex])/10)*10).format()+"/"+Number(value).format()+" "
                      + "(" + Math.round((Number(total[c.dataIndex] - breakfast[c.dataIndex] - lunch[c.dataIndex] - dinner[c.dataIndex] - snack[c.dataIndex])/10)*10).format() + ")\r\n";
                    return t_;
                  } else {
                    return "";
                    //return Number(value).format();
                  }

                }

                else return '';
              }
            }
          },
        },
      };

      if( document.getElementById('macro2_') !==null) {
        const chart2_ctx = document.getElementById('macro2_').getContext('2d');
        this.chart2 = new this.$Chart(chart2_ctx, option);
      }

    },
    reload_info: async function() {

      let t_ = null;

      // const today = this.$moment().format("YYYY-MM-DD");

      // if ( this.date != today ) {
        // 날이 다르면 다르게 계산해야함.

      const id_token = await firebase.auth().currentUser.getIdToken();
      const cheat_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/cheating/one', {
        params: { email: this.user.data.email, date: this.date },
        headers: {'id_token': id_token},
      });

      if ( cheat_.data.length > 0 ) {

        let latest_ = cheat_.data[0];
        this.is_cheating = true;
        this.diet_status_msg = "치팅데이";
        this.calory = latest_.calculated_calory;

        let food_ = {
          carb: {
            percentage: Math.round(latest_.carb/latest_.calculated_calory*100),
          },
          protein: {
            kcal: Math.round(latest_.protein),
          }
        }

        t_ = calc_food(latest_.calculated_calory, food_);

      } else {
        // 치팅이 아님(다이어트나 유지어트)

        this.is_cheating = false;
        this.diet_status_msg = "";

        const diet_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/diet', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        let diet_selected = null;

        if ( diet_.data.length > 0 ) {
          for ( const each of diet_.data ) {
            if ( each.date !== null ) {
              let e_day = (each.date_f === null || each.date_f == "") ? this.$moment().format("YYYY-MM-DD") : each.date_f ;
              if ( each.date <= this.date && e_day >= this.date ) {
                if ( each.date_f != this.date)
                  diet_selected = each;
              }
            }
          }

          // 다이어트인가?
          if ( diet_selected !== null ) {

            let diff = 0;

            const cheat2_ = await axios.get(
              api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/cheating/range/', {
              params: { email: this.user.data.email, date: diet_selected.date, date2: this.date },
              headers: {'id_token': id_token},
            });

            const cheat2_data_ = cheat2_.data;

            if ( cheat2_data_ !== undefined ) {

              for ( let each of cheat2_data_ ) {
                diff++;
              }

              // let week_sday = this.$moment(cheat2_data_[0].date, "YYYY-MM-DD").add(7*i, 'days').format("YYYY-MM-DD");
              // let week_eday = this.$moment(cheat2_data_.date, "YYYY-MM-DD").add(7*(i+1), 'days').format("YYYY-MM-DD");

              // for ( let each of cheat2_.data ) {
              //   if ( week_sday <= each.date && week_eday > each.date ) {
              //     diff++;
              //   }
              // }
            }

            let weeks = this.$moment(this.date, "YYYY-MM-DD").diff(this.$moment(diet_selected.date, "YYYY-MM-DD"), 'weeks');

            this.diet_status_msg = "다이어트 중";
            this.calory = Number(diet_selected.calculated_calory - diet_selected.calory_delta_init - diet_selected.calory_delta_weekly*(weeks-diff));
            t_ = calc_food(this.calory, this.data.food);

            await this.$store.dispatch('fetchFood', t_);

            return;
          }
        }

        const maintain_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/maintain/latest', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        let maintain_selected = null;

        if ( maintain_.data.length > 0 ) {
          for ( const each of maintain_.data ) {
            if ( each.date !== null ) {

              let e_day = (each.date_f === null || each.date_f == "") ? this.$moment().format("YYYY-MM-DD") : each.date_f ;
              if ( each.date_f == this.date ) break;
              if ( each.date <= this.date && e_day >= this.date ) {
                maintain_selected = each;
              }
            }
          }

          // 유지어트 인가?
          if ( maintain_selected !== null) {

            let weeks = this.$moment().diff(this.$moment(maintain_selected.date, "YYYY-MM-DD"), 'weeks');

            this.diet_status_msg = "유지어트 중";

            let selected_speed_ = 7;
            let calory_delta_weekly_plus_ = 0;

            if ( maintain_selected.mode == 1 ) {

              // 유지어트 칼로리 계산
              if ( maintain_selected.calory_diff_simple < 700 ) {

                // (2) 700칼로리 미만시 (N/B)
                // 1주차 섭취칼로리 + (N/B)
                // 2주차 섭취칼로리 + (N/B)*2
                // 3주차 섭취칼로리 + (N/B)*3
                // 4주차 섭취칼로리 + (N/B)*4

                switch(maintain_selected.speed) {
                case 2:
                  selected_speed_ = 8;
                  break;
                case 3:
                  selected_speed_ = 6;
                  break;
                case 4:
                  selected_speed_ = 4;
                  break;
                }

                // (N/B)
                calory_delta_weekly_plus_ = Math.round(((maintain_selected.calory_diff_simple)/selected_speed_)/10)*10;
                this.calory = Number(maintain_selected.calculated_calory + calory_delta_weekly_plus_ * weeks);

              } else {

                // (1) 700칼로리 이상시 ((N-500) /A)
                // 1주차 섭취칼로리 +500
                // 2주차 섭취칼로리 +500 + ((N-500) /A)
                // 3주차 섭취칼로리 +500 + (((N-500) /A)*2)
                // 4주차 섭취칼로리 +500 + (((N-500) /A)*3)

                switch(maintain_selected.speed) {
                case 2:
                  selected_speed_ = 7;
                  break;
                case 3:
                  selected_speed_ = 5;
                  break;
                case 4:
                  selected_speed_ = 3;
                  break;
                }

                // 500 + (( N-500) /A)
                calory_delta_weekly_plus_ = Math.round(((maintain_selected.calory_diff_simple-500)/selected_speed_)/10)*10;
                this.calory = Number(maintain_selected.calculated_calory +
                                    calory_delta_weekly_plus_*weeks);

              }



            } else {


              calory_delta_weekly_plus_ = Number(Number(maintain_selected.speed*maintain_selected.calory_delta_weekly).toFixed(1));
              this.calory = Math.round(( maintain_selected.calculated_calory +
                                          calory_delta_weekly_plus_*weeks)/10)*10;

            }

            t_ = calc_food(this.calory, this.data.food);


            await this.$store.dispatch('fetchFood', t_);

            return;
          }
        }

        {
          const cycling_ = await axios.get(
            api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/cycling/one', {
            params: { email: this.user.data.email, date: this.date },
            headers: {'id_token': id_token},
          });

          // 탄수화물 사이클링이 저장된 경우
          if ( cycling_.data.length > 0 ) {
            let cdata_ = cycling_.data[0];

            this.data.calory_delta = cdata_.calory_diff;
            this.data.calculated_calory = cdata_.calory;

            t_ = {
              carb: {
                percentage: Math.round((cdata_.carb/cdata_.calory*100)/10)*10,
                kcal: cdata_.carb,
                gram: Math.round(cdata_.carb/4*10)/10,
              },
              protein: {
                percentage: Math.round((cdata_.protein/cdata_.calory*100)/10)*10,
                kcal: cdata_.protein,
                gram: Math.round(cdata_.protein/4*10)/10,
              },
              fat: {
                percentage: Math.round((cdata_.fat/cdata_.calory*100)/10)*10,
                kcal: cdata_.fat,
                gram: Math.round(cdata_.fat/9*10)/10,
              }
            }

            this.calory = Number(cdata_.calory);
            await this.$store.dispatch("fetchDietary", 5);
            this.diet_status_msg = "탄수화물 사이클링";
            await this.$store.dispatch('fetchFood', t_);

          } else {

            // 일반

            this.calory = Number(this.tdee + this.data.calory_delta);

            t_ = calc_food(
              this.tdee +
              this.data.calory_delta,
              this.data.food);

            await this.$store.dispatch('fetchFood', t_);
          }
        }

      }

      // await this.$store.dispatch('fetchFood', t_);

      // this.calc_macro_info();
    },

    calc_macro_info: async function() {
      let tmp;

      tmp = {
        proteinfromfoods: Number(Number(calc_food_protein(this.foodlist)).toFixed(1)),
      };
      await this.$store.dispatch("fetchDietary", tmp);

      tmp = {
        proteinremains: Number(Number(this.data.food.protein.gram).toFixed(1)),
      };
      await this.$store.dispatch("fetchDietary", tmp);

      tmp = {
        proteintotal: Number(Number(this.data.dietary.proteinremains - this.data.dietary.proteinfromfoods).toFixed(1)),
      };
      await this.$store.dispatch("fetchDietary", tmp);

      let gram_ = await calc_protein(this.user.sex, this.data.weight, this.data.fat, this.data.protein_multiplier);
      let kcal_ = this.data.food.protein.gram*4;

      // const food_ = calc_food(this.tdee + this.data.calory_delta, {
      const food_ = calc_food(this.calory, {
        ...this.data.food,
        protein: {
          gram: gram_,
          kcal: kcal_,
        }
      })

      await this.$store.dispatch('fetchFood', food_);

      await this.draw_graph();
    },

    changeCaloriesDelta: async function(v) {
      if ( Math.abs(v) > 1000 ) {
        alert('-1000 ~ 1000사이 값을 입력하세요.')
        return;
      }
      await this.$store.dispatch('fetchCaloryDelta', v);

      await this.reload_info();
      await this.draw_graph();

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity', {
        email: this.user.data.email,
        date: this.date,
        calory_delta: Number(this.data.calory_delta),
      }, {
        headers: {'id_token': id_token},
      });


    },

    changeWantedCarb: async function(v) {

      if ( this.is_cheating ) {
        if ( v < 60 ) {
          alert("치팅데이에는 탄수화물 비율을 60이상으로 제한합니다.");
          await this.$store.dispatch('fetchFood', {
            carb: {
              percentage: 60,
            }
          });
          return;
        }
      }

      await this.$store.dispatch('fetchFood', {
        carb: {
          percentage: v,
        }
      });

      await this.reload_info();
      await this.draw_graph();

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/foodmacro', {
        email: this.user.data.email,
        date: this.date,
        carb_percentage: this.data.food.carb.percentage,
        carb_kcal: this.data.food.carb.kcal,
        carb_gram: this.data.food.carb.gram,
        protein_percentage: this.data.food.protein.percentage,
        protein_kcal: this.data.food.protein.kcal,
        protein_gram: this.data.food.protein.gram,
        fat_percentage: this.data.food.fat.percentage,
        fat_kcal: this.data.food.fat.kcal,
        fat_gram: this.data.food.fat.gram,
      }, {
        headers: {'id_token': id_token},
      });
    },

    changeRawFoodAmount: async function(v) {

      this.editedItem.carb = this.selectedItem.carb * Number(v);
      this.editedItem.protein = this.selectedItem.protein * Number(v);
      this.editedItem.fat = this.selectedItem.fat * Number(v);
      this.editedItem.calory = this.selectedItem.calory * Number(v);
    },

    changeMeal: async function(v) {
      this.editedItem.meal = v;
    },

    changeMealManual: async function(v) {
      this.editedItemManual.meal = v;
    },

    selectRawFood: async function(v) {

      this.editedItem = this.$lodash.cloneDeep(v);
      this.editedItem.amount = 1;
      this.food_selected = true;

      if ( v._id === undefined ) {
        this.hour_disabled = false;
      } else {
        this.hour_disabled = true;
      }
      this.editedItem.time = 1;
    },

    editItem: function (item) {
      this.editedIndex = this.foods.indexOf(item);
      this.editedItem = this.$lodash.cloneDeep(item);
      this.dialog = true;
      this.isEdit = true;
    },

    deleteItem: async function (meal_id, index) {
      confirm('삭제하시겠습니까?') && this.foods_refined[""+meal_id+""].list.splice(index, 1);

      let foods_ = this.foods_refined.breakfast.list;
      foods_ = foods_.concat(this.foods_refined.lunch.list);
      foods_ = foods_.concat(this.foods_refined.dinner.list);
      foods_ = foods_.concat(this.foods_refined.snack.list);

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/foodhistory', {
        email: this.user.data.email,
        date: this.date,
        foodlist: foods_,
      }, {
        headers: {'id_token': id_token},
      });

      this.foods = foods_;
      await this.$store.dispatch("fetchFoodlist", foods_);

      await this.reload_info();
      await this.reload_food_list(foods_);

      await this.draw_graph();
    },

    close: function () {
      this.dialog = false
      this.food_selected = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    close2: function () {
      this.food_selected = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemManual = Object.assign({}, this.defaultItemManual)
        this.editedIndex = -1
      }, 300)
    },

    save2: async function () {
      if ( !this.food_selected ) {
        alert("음식을 선택하세요.");
        return false;
      }

      this.food_selected = false;

      let t = this.$lodash.cloneDeep(this.editedItem);
      if (this.editedIndex > -1) {
        Object.assign(this.foods[this.editedIndex], t)
      } else {
        this.foods.push(t);
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/foodhistory', {
        email: this.user.data.email,
        date: this.date,
        foodlist: this.foods,
      }, {
        headers: {'id_token': id_token},
      });

      await this.$store.dispatch("fetchFoodlist", this.foods);

      (async () => {
        await this.get_info();
        await this.reload_info();
        //await this.get_templates();

        await this.calc_diet_period();

        await this.calc_macro_info();

        this.loading = false;

        await this.draw_graph();

      })();

      this.isEdit = false;

      this.close2()
    },

    save_manual_food: async function () {

      let t = this.$lodash.cloneDeep(this.editedItemManual);
      if (this.editedIndex > -1) {
        Object.assign(this.foods[this.editedIndex], t)
      } else {
        this.foods.push(t);
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/foodhistory', {
        email: this.user.data.email,
        date: this.date,
        foodlist: this.foods,
      }, {
        headers: {'id_token': id_token},
      });

      await this.$store.dispatch("fetchFoodlist", this.foods);

      (async () => {
        await this.get_info();
        await this.reload_info();
        //await this.get_templates();

        await this.calc_diet_period();

        await this.calc_macro_info();

        this.loading = false;

        await this.draw_graph();

      })();

      this.isEdit = false;

      this.close2()

    },

    //

    // getDayFormat: function(date) {
    //   return this.$moment(date, "YYYY-MM-DD").format("D");
    // },

    // pick_date: async function(e) {
    //   this.loading = true;
    //   this.date = e;
    //   this.day = this.$moment(e, "YYYY-MM-DD").format("dddd");

    //   (async () => {
    //     await this.get_info();
    //     await this.reload_info();
    //     //await this.get_templates();

    //     await this.calc_diet_period();

    //     await this.calc_macro_info();

    //     this.loading = false;

    //     await this.draw_graph();

    //   })();

    // },

    del_cycling: async function() {

      if (!confirm("삭제하시겠습니까?")) return;
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/cycling/', {
          data: {
            email: this.user.data.email,
            date: this.date,
          },
          headers: {'id_token': id_token},
      });
      await this.$store.dispatch('fetchCaloryDelta', 0);
      await this.$store.dispatch('fetchFood', {
        carb: {
          percentage: 50,
        }
      });
      this.diet_status_msg = "";
      this.reload_info();
    },

    onPressYesterday: async function() {
      await this.$store.dispatch('postDate', this.$moment(this.date,'YYYY-MM-DD').subtract(1, 'd').format('YYYY-MM-DD'));
    },
    onPressTomorrow: async function() {
      await this.$store.dispatch('postDate', this.$moment(this.date,'YYYY-MM-DD').add(1, 'd').format('YYYY-MM-DD'));
    },

    changeProteinMultiplier: async function () {
      await this.$store.dispatch('fetchProteinMultiplier', this.protein_multiplier);
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/history', {
        email: this.user.data.email,
        date: this.date,
        protein: this.protein_multiplier,
      }, {
        headers: {'id_token': id_token},
      });


      let gram_ = await calc_protein(this.user.sex, this.data.weight, this.data.fat, this.protein_multiplier);
      let kcal_ = this.data.food.protein.gram*4;

      const food_ = calc_food(this.tdee + this.data.calory_delta, {
        ...this.data.food,
        protein: {
          gram: gram_,
          kcal: kcal_,
        }
      })

      await this.$store.dispatch('fetchFood', food_);

      await this.calc_macro_info();
      await this.draw_graph();
    },

    customSearch: function(value, search, item) {
      return ( String(item.name).includes(search) || String(item.name_search).includes(search))
    },

    // 식단 템플릿 공유 관련

    change_template_shared_name : function() {
      if ( this.defaultItem_template_shared.name == "" ) {
        this.template_selected = false;
      } else {
        this.template_selected = true;
      }
    },

    change_template_shared_text : function() {
      if ( this.defaultItem_template_shared.text == "" ) {
        this.template_selected = false;
      } else {
        this.template_selected = true;
      }
    },

    share_template: async function() {

      let item_ = {
        ...this.selected_shared_template,
        title: this.defaultItem_template_shared.name,
        text: this.defaultItem_template_shared.text,
      };

      if ( confirm('공유하시겠습니까?') ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food_template/share/', {
          email: this.user.data.email,
          item: item_,
        },{
          headers: {'id_token': id_token},
        });

        await this.list_template_shared();
        await this.list_template_shared_mine();
        await this.list_template_shared_popular();
        await this.list_template_shared_latest();

        this.close_template_shared();
      }

    },

    search_template: async function() {
      const id_token = await firebase.auth().currentUser.getIdToken();
      const res_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food_template/share/search/', {
        params: { email: this.user.data.email, search: this.search_template_name },
        headers: {'id_token': id_token},
      });

      this.shared_templates_searched = res_.data;
    },

    unshare_template: async function(item) {
      if ( confirm('공유 제거 하시겠습니까?') ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food_template/unshare/', {
          email: this.user.data.email,
          item_id: item._id,
        },{
          headers: {'id_token': id_token},
        });

        await this.list_template_shared();
        await this.list_template_shared_mine();
        await this.list_template_shared_popular();
        await this.list_template_shared_latest();
      }
    },

    list_template_shared: async function() {

      const id_token = await firebase.auth().currentUser.getIdToken();
      const res_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food_template/share/', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      const res2_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food_template/share/count/', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      this.shared_template_count = res2_.data;
      this.shared_templates = res_.data;
    },

    list_template_shared_mine: async function() {

      const id_token = await firebase.auth().currentUser.getIdToken();
      const res_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food_template/share/mine/', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      this.shared_templates_mine = res_.data;
    },

    list_template_shared_latest: async function() {

      const id_token = await firebase.auth().currentUser.getIdToken();
      const res_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food_template/share/latest/', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      this.shared_templates_latest = res_.data;
    },

    list_template_shared_popular: async function() {

      const id_token = await firebase.auth().currentUser.getIdToken();
      const res_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food_template/share/popular/', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      this.shared_templates_popular = res_.data;
    },

    import_template: async function(item) {

      let obj = this.food_templates.find(o=>o.name===item.title);
      if ( obj !== undefined ) {
        alert('같은 이름이 존재합니다.');
        return
      }

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food_template/import/', {
        email: this.user.data.email,
        name: item.title,
        foodlist: item.foodlist,
        original_id: item._id,
      }, {
        headers: {'id_token': id_token},
      });

      this.get_templates();

    },


    confirm_change_template_name: async function (item) {

      const name_ = this.food_templates_edit_flag[item._id];

      for ( let each of this.food_templates ) {
        if ( each._id == item._id ) {
          this.$set(each, "name", name_);
        }
      }

      this.$set(this.food_templates_edit_flag, item._id, '');

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food_template/edit/name', {
        email: this.user.data.email,
        id: item._id,
        name: name_,
      }, {
        headers: {'id_token': id_token},
      });

    },

  },
}
</script>

<style scoped>

canvas#macro_ {
  max-height: 150px;
  min-height: 150px;
  height: 150px;
  width: 150px;
}

canvas#macro2_ {
  max-height: 250px;
  min-height: 250px;
  height: 250px;
  width: 100%;
}

.v-divider {
  border-color: #FBDE44FF !important;
}

.top_graph {
  background-color: #eaeaea;
}

.op_btn {
  position:absolute;
  right:0;
  top:0;
}

.op_btn::before {
  color: transparent;
}

.op_btn::hover {
  color: transparent;
}

.v-btn:not(.v-btn--round).v-size--x-small {
  min-width: 10px;
}


.v-btn:not(.v-btn--round).v-size--small {
  min-width: 0;
}

.edit_input >>> input {
  font-size: 14px !important;
  padding: 3px !important;

}

.edit_input >>> .v-input__slot {
  margin:0;
}

.edit_input >>> .v-messages__message {
  font-size: 9px !important;
  float: right !important;
  padding:0;
}

</style>
