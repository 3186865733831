<template>
  <div class="basic" v-if="data.weight > 0 && data.muscle > 0 && data.fat > 0">
    <v-row dense v-if="!loaded">
      <v-container fluid class="ma-10">
        <v-row dense>
          <v-col cols="12">
            <v-row class="align-center justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-col cols="12" align=center>
                기본 정보를 불러오고 있습니다.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row no-gutters v-if="loaded">
      <v-col cols="12" class="ma-0 pa-3" style="background-color:#141E30;color:white;font-size:30px;font-weight:bold;text-align:center;">
        <span @click="onPressYesterday">&lt;</span>
        {{ date }}
        <span @click="onPressTomorrow">&gt;</span>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="loaded" align="center" justify="center">
      <v-col cols=12>
        <v-card flat class="subtitle-2 pa-4 ma-3 black--text" color="#FBDE44AA">
          <v-row no-gutters>
            <v-col md=10 cols=6>
              <div class="info_title font-weight-bold">활동 대사량</div>
            </v-col>
            <v-col md=2 cols=6 align=right>
              <div>기초대사량 : <b>{{ base_calory.format() }}</b> Kcal</div>
            </v-col>
          </v-row>
          <div class="recommended font-weight-bold" align="center">{{ tdee.format() }}</div>
          <div class="unit">Kcal</div>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="loaded && guide" align="center" justify="center">
      <v-col cols="12">
        <v-card flat class="subtitle-2 pa-4 ma-3  mt-1" align="left">
          {{ guide == 1 ? '커팅' :
             guide == 2 ? '다이어트' :
             guide == 3 ? '벌크업' :
             guide == 4 ? '린매스업' :
             guide == 5 ? '근육량증가' :
             guide == 6 ? '상승 다이어트' : '일반' }}
          가이드를 선택하셨습니다. 24시간 활동을 입력하시고, 진행하기 버튼을 눌러주세요.

          <v-btn color="#FBDE44FF" class="elevation-0 op_btn ma-2" :disabled="activity_time<24" @click="open_guide" >
            진행하기
          </v-btn>

        </v-card>

        <v-dialog
          v-model="dialog_guide"
          :fullscreen="mobile"
          hide-overlay transition="fade-transition"
          @click:outside="close_guide"
          width="800">
          <v-card class="pa-0 ma-0"  style="background-color:#f5f5f5;">
            <v-toolbar dark color="#FBDE44FF" v-if="mobile" class="mb-5">
              <v-btn icon color="black" @click="close_guide">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title class="black--text">칼로리 가이드</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn color="#FBDE44FF" text class="black--text" @click="goto_guide">
                  {{ guide == 1 ? '다이어트 도우미로 이동' :
                      guide == 2 ? '다이어트 도우미로 이동' :
                      guide == 3 ? '식단 관리로 이동' :
                      guide == 4 ? '식단 관리로 이동' :
                      guide == 5 ? '식단 관리로 이동' :
                      guide == 6 ? '식단 관리로 이동' : '식단 관리로 이동' }}
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-card-text no-gutters class="pb-0">
              <!-- // 굳 0, 커팅 1, 다이어트 2, 벌크업 3, 린매스업 4, 근육량 증가 5, 상승 6 -->
              <v-row  no-gutters>
                <v-col sm="12" cols="12">
                  <v-card flat class="small subtitle-2 pa-5 mt-3 mb-2">
                    <div class="font-weight-bold" style="height:25px; font-size:1.5em;">
                      {{ guide == 1 ? '커팅' :
                        guide == 2 ? '다이어트' :
                        guide == 3 ? '벌크업' :
                        guide == 4 ? '린매스업' :
                        guide == 5 ? '근육량증가' :
                        guide == 6 ? '상승 다이어트' : '일반' }} 가이드
                    </div>
                    <div style="line-height:1.8em">
                      - 운동 초보자는 단백질 2g/kg 추천합니다.
                    </div>
                    <div style="line-height:1.8em" v-if="guide == 1">
                      - 커팅은 다이어트 도우미를 이용 바랍니다.
                    </div>
                    <div style="line-height:1.8em" v-if="guide == 2">
                      - 다이어트는 다이어트 도우미를 이용 바랍니다.
                    </div>
                    <div style="line-height:1.8em" v-if="guide == 3">
                      - 벌크업을 선택하셨습니다.<br/>
                      - 칼로리는 200 ~ 300kcal, 권장 단백질 섭취 범위는 1.6 ~ 2.2g/kg 입니다.
                    </div>
                    <div style="line-height:1.8em" v-if="guide == 4">
                      - 린매스업을 선택하셨습니다.<br/>
                      - 칼로리는 -200 ~ 200kcal, 권장 단백질 섭취 범위는 1.6 ~ 2.2g/kg 입니다.
                    </div>
                    <div style="line-height:1.8em" v-if="guide == 5">
                      - 근육량 증가를 선택하셨습니다.<br/>
                      - 칼로리는 0 ~ 200kcal, 권장 단백질 섭취 범위는 1.6 ~ 2.2g/kg 입니다.
                    </div>
                    <div style="line-height:1.8em" v-if="guide == 6">
                      - 상승 다이어트를 선택하셨습니다.<br/>
                      - 칼로리는 -200 ~ 200kcal, 권장 단백질 섭취 범위는 1.6 ~ 2.2g/kg 입니다.
                    </div>
                  </v-card>
                </v-col>
              </v-row>
              <v-row  no-gutters v-if="guide!=1 && guide!=2">
                <v-col cols="12">
                  <v-card flat class="small subtitle-2 pt-5 pr-2 pl-2">
                    <v-row no-gutters>
                      <v-col cols="6"  class="small subtitle-2 pa-2">
                        <v-text-field
                          dense
                          v-model.number="protein_multiplier"
                          class="center-input"
                          label="체중당 섭취할 단백질"
                          :type="mobile?'number':''"
                          required
                          :hint="'g/kg'"
                          persistent-hint
                          v-on:change="changeProteinMultiplier">
                          <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                            <template v-slot:activator="{ on }">
                              <v-icon color="grey" class="load" v-on="on">mdi-comment-question-outline</v-icon>
                            </template>
                            <span class="caption">
                              <div>체지방율 20% 초과의 경우 20%로 환산. (여성의 경우 25%)</div>
                              <div>체중당 섭취할 단백질은 초보자의 경우 2g/kg, 일반적으로 1.6g/kg 권장.</div>
                            </span>
                          </v-tooltip>
                        </v-text-field>
                        <div align=center>
                          <span>권장 총 단백질 섭취량 : </span>
                          <span style="font-size:1.2em;font-weight:bold;">{{ data.food.protein.gram }}</span>
                          <span>g</span>
                        </div>
                      </v-col>
                      <v-col cols="6"  class="small subtitle-2 pa-2">
                        <v-text-field
                          dense
                          :disabled="dietstatus > 0"
                          v-model.number="data.calory_delta"
                          class="center-input"
                          label="칼로리 조절"
                          hint="Kcal"
                          persistent-hint
                          v-on:change="changeCaloriesDelta" >
                          <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                            <template v-slot:activator="{ on }">
                              <v-icon color="grey" class="load" v-on="on">mdi-comment-question-outline</v-icon>
                            </template>
                            <span class="caption">
                              <div>린매스업 : +-200Kcal</div>
                              <div>벌크업 : +200~300Kcal</div>
                              <div>다이어트 : -500~700Kcal</div>
                            </span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions v-if="!mobile"  class="ma-4 mb-0 mt-3 pb-3 pt-0">
              <v-btn color="#FBDE44FF" block class="black--text" @click="goto_guide">
                {{ guide == 1 ? '다이어트 도우미로 이동' :
                    guide == 2 ? '다이어트 도우미로 이동' :
                    guide == 3 ? '식단 관리로 이동' :
                    guide == 4 ? '식단 관리로 이동' :
                    guide == 5 ? '식단 관리로 이동' :
                    guide == 6 ? '식단 관리로 이동' : '식단 관리로 이동' }}
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="loaded" align="center" justify="center" >
      <v-col cols="12">
        <v-card flat class="subtitle-2 pa-4 ma-3  mt-1" align="left">

          <v-row>
            <v-col cols=8 class="pa-4 pt-0 pb-0">

              <v-row dense>
                <v-col cols="12" class="subtitle-2" style="font-weight:bold; font-size:1.1em;">
                  총 활동 <span style="margin:5px;">{{activity_time}}</span>시간
                  <span style="font-size:0.8em">( 24시간 기준)</span>
                  <div v-if="activity_time != 24" style="color:red">활동 정보 {{ 24-activity_time }} 시간 부족</div>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="6" align="center" class="caption" style="border-bottom: 2px solid rgb(68, 114, 199)">Time</v-col>
                <v-col cols="6" align="center" class="caption" style="border-bottom: 2px solid rgb(112, 173, 71)">활동강도</v-col>
                <!-- <v-col cols="4" align="center" class="caption" style="border-bottom: 2px solid #f0b111">P*T</v-col> -->
              </v-row>

              <v-row dense>
                <v-container fluid v-if="loading" class="ma-10">
                  <v-row dense>
                    <v-col cols="12">
                      <v-row class="align-center justify-center">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                        <v-col cols="12" align=center>
                          활동 정보를 불러오고 있습니다.
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
                <v-col cols="12" v-for="(each, i) in activity" :key="i">
                  <v-card outlined class="elevation-0 pa-2 ma-0">
                    <v-row dense>
                      <v-col cols="5" class="subtitle-2">
                        {{ each.name }}
                      </v-col>
                      <v-col cols="3" align="right" class="caption" style="border-bottom: 1px solid rgb(68, 114, 199)">{{ each.time }} <span style="font-size:10px;">hr</span></v-col>
                      <v-col cols="3" align="right" class="caption" style="border-bottom: 1px solid rgb(112, 173, 71)">{{ each.par }}</v-col>
                      <!-- <v-col cols="2" align="left" class="caption" style="border-bottom: 1px solid #f0b111">{{ Math.round(each.par*each.time*100)/100 }}</v-col> -->
                      <v-col cols="1">
                        <v-btn xSmall color="#FBDE44FF" class="elevation-0 op_btn" @click="deleteItem(i)" >
                          <v-icon color="black" small>close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>

            </v-col>
            <v-col cols=4>
              활동 템플릿
              <v-btn text small @click.stop="dialog_template = true" color="primary">
                <v-icon small >assignment_turned_in</v-icon> 템플릿 저장
              </v-btn>

              <v-row no-gutters v-for="each in activity_templates" :key="each._id">
                <v-col>
                  <v-sheet
                    close
                    label
                    color="#FBDE44FF"
                    class="ma-1 pa-2"
                    @click="load_template(each)">
                    <v-row no-gutters>
                      <v-col md=9 cols=6 v-if="activity_templates_edit_flag[each._id]==''" class="pt-1 pl-1">
                        {{each.name}}
                      </v-col>
                      <v-col md=9 cols=6 v-else>
                        <v-text-field
                          no-gutters
                          class="ma-0 pa-0 edit_input"
                          v-model="activity_templates_edit_flag[each._id]"
                          label="템플릿명"
                          single-line
                          hint="엔터키를 치세요"
                          persistent-hint
                          @keyup.enter="confirm_change_template_name(each)"
                        />
                      </v-col>
                      <v-col md=1 cols=2 align='right'>
                        <v-btn text small style="width:30px;" @click.stop.prevent="edit_template_name(each)">
                          <v-icon color="black" small>list</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col md=1 cols=2 align='right'>
                        <v-btn text  @click.stop="dialog_template_shared = true; selected_shared_template = each" small style="width:30px;">
                          <v-icon color="blue" small>share</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col md=1 cols=2 align='right'>
                        <v-btn text @click.stop.prevent="delete_template(each)" small style="width:30px;">
                          <v-icon color="red" small>cancel</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-sheet
                    label
                    dark
                    color="#28334AFF"
                    class="ma-1 pa-2"
                    key="activity_1"
                    @click="load_work1">빈둥빈둥</v-sheet>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-sheet
                    label
                    dark
                    color="#28334AFF"
                    class="ma-1 pa-2"
                    key="activity_2"
                    @click="load_work2">좌식업무</v-sheet>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-sheet
                    label
                    dark
                    color="#28334AFF"
                    class="ma-1 pa-2"
                    key="activity_3"
                    @click="load_work3">돌아다니는 업무</v-sheet>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-sheet
                    label
                    dark
                    color="#28334AFF"
                    class="ma-1 pa-2"
                    key="activity_4"
                    @click="load_work4">활동적인 업무</v-sheet>
                </v-col>
              </v-row>


              <v-row no-gutters>
                <v-col>
                  <v-sheet
                    label
                    dark
                    color="#28334AFF"
                    class="ma-1 pa-2"
                    key="activity_5"
                    @click="load_work5">일반 직장인</v-sheet>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-sheet
                    label
                    dark
                    color="#28334AFF"
                    class="ma-1 pa-2"
                    key="activity_6"
                    @click="load_work6">휴일 직장인</v-sheet>
                </v-col>
              </v-row>

            </v-col>
          </v-row>

        </v-card>

        <v-card flat class="body-2 pa-4 ma-3" style="border-radius:10px; border:4px solid #dddddd;">
          칼로리는 추측값입니다. 같은 활동이라도 자세에 따라 다릅니다.<br/>
        </v-card>

        <v-row no-gutters class="ma-3">
          <v-col md="4" cols="12">
            <v-card flat class="body-2 pa-2 ma-2">
              <v-card-title class="ma-0 pa-0 mr-4 ml-4 mb-3" style="font-size:15px;font-weight:bold;border-bottom:1px solid #ddd;">
                새로운 활동 템플릿
              </v-card-title>
              <v-card-text>
                <v-row no-gutters v-for="each in shared_templates_latest" :key="each._id">
                  <v-col>
                    <v-sheet
                      close
                      label
                      class="ma-0 pa-0" >
                      <v-row no-gutters>
                        <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                          <template v-slot:activator="{ on }">
                            <v-col md=10 cols=9 class="pa-1"  v-on="on">
                              <span style="font-weight:500;">{{each.title}}</span> <br/>
                              <span style="font-size:12px;color:#666">{{each.nickname}}</span>
                            </v-col>
                          </template>
                          <span class="caption">
                            {{ each.text }}
                          </span>
                        </v-tooltip>
                        <v-col md=2 cols=3  align='right' >
                          <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                            <template v-slot:activator="{ on }">
                              <v-btn text @click.stop.prevent="import_template(each)" small align="left">
                                <v-icon color="rgb(40, 51, 74)" v-on="on">mdi-import</v-icon>
                              </v-btn>
                            </template>
                            <span class="caption">
                              가져오기
                            </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4" cols="12">
            <v-card flat class="body-2 pa-2 ma-2">
              <v-card-title class="ma-0 pa-0 mr-4 ml-4 mb-3" style="font-size:15px;font-weight:bold;border-bottom:1px solid #ddd;">
                인기 활동 템플릿
              </v-card-title>
              <v-card-text>
                <v-row no-gutters v-for="each in shared_templates_popular" :key="each._id">
                  <v-col>
                    <v-sheet
                      close
                      label
                      class="ma-0 pa-0" >
                      <v-row no-gutters>
                        <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                          <template v-slot:activator="{ on }">
                            <v-col md=10 cols=9 class="pa-1"  v-on="on">
                              <span style="font-weight:500;">{{each.title}}</span> <br/>
                              <span style="font-size:12px;color:#666">{{each.nickname}}</span>
                            </v-col>
                          </template>
                          <span class="caption">
                            {{ each.text }}
                          </span>
                        </v-tooltip>
                        <v-col md=2 cols=3  align='right' >
                          <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                            <template v-slot:activator="{ on }">
                              <v-btn text @click.stop.prevent="import_template(each)" small align="left">
                                <v-icon color="rgb(40, 51, 74)" v-on="on">mdi-import</v-icon>
                              </v-btn>
                            </template>
                            <span class="caption">
                              가져오기
                            </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4" cols="12">
            <v-card flat class="body-2 pa-2 ma-2">
              <v-card-title class="ma-0 pa-0 mr-4 ml-4 mb-3" style="font-size:15px;font-weight:bold;border-bottom:1px solid #ddd;">
                내 공유 활동 템플릿
              </v-card-title>
              <v-card-text>
                <v-row no-gutters v-for="each in shared_templates_mine" :key="each._id">
                  <v-col>
                    <v-sheet
                      close
                      label
                      class="ma-0 pa-0" >
                      <v-row no-gutters>
                        <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                          <template v-slot:activator="{ on }">
                            <v-col md=10 cols=9 class="pa-1"  v-on="on">
                              <span style="font-weight:500;">{{each.title}}</span> <br/>
                              <span style="font-size:12px;color:#666">{{each.nickname}}</span>
                            </v-col>
                          </template>
                          <span class="caption">
                            {{ each.text }}
                          </span>
                        </v-tooltip>
                        <v-col md=2 cols=3  align='right' >
                          <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                            <template v-slot:activator="{ on }">
                              <v-btn text @click.stop.prevent="unshare_template(each)" small align="left">
                                <v-icon color="red" small v-on="on">share</v-icon>
                              </v-btn>
                            </template>
                            <span class="caption">
                              공유 해제
                            </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-card flat class="body-2 pa-2 ma-3" >
          <v-card-title class="ma-0 pa-0 mr-4 ml-4 mb-3" style="font-size:15px;font-weight:bold;border-bottom:1px solid #ddd;">
            총 공유 활동 템플릿 : {{ shared_template_count }} 개
          </v-card-title>
          <v-card-text>
            <v-row no-gutters >
              <v-col md="3" cols="12">
                <v-row  no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      v-model="search_template_name"
                      append-icon="search"
                      label="공유 템플릿 검색"
                      outlined
                      single-line
                      hide-details
                      class="dialog_search"
                      @click:append="search_template"
                      @keyup.enter="search_template"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="shared_templates_searched.length > 0">
                  <v-col>
                    검색된 템플릿 : {{ shared_templates_searched.length }} 개
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="9" cols="12" class="pa-0 pr-0 pl-1">
                <v-row no-gutters v-for="each in shared_templates_searched" :key="each._id">
                  <v-col>
                    <v-sheet
                      close
                      label
                      class="ma-1 mt-0 mb-2 pa-1" style="border:1px solid #dddddd; border-radius:5px;">
                      <v-row no-gutters>
                        <v-col md=10 cols=9 class="pa-1">
                          <span style="font-weight:500;font-size:1.2em;">{{each.title}}</span> <br/>
                          <span style="font-size:12px;color:#666">{{each.nickname}}</span>
                        </v-col>
                        <v-col md=2 cols=3  align='right' >
                          <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                            <template v-slot:activator="{ on }">
                              <v-btn text @click.stop.prevent="import_template(each)" small align="left">
                                <v-icon color="rgb(40, 51, 74)" v-on="on">mdi-import</v-icon>
                              </v-btn>
                            </template>
                            <span class="caption">
                              가져오기
                            </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col class="pa-1" style="border-top:1px solid #efefef" v-html="each.text.split('\n').join('<br />')">

                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
<!--
        <v-card flat class="big pa-4 ma-3 mt-0 rounded-lg">
          <span style="color:red">모두 입력하셨다면, 식단관리로 이동하여 식단을 입력하세요.</span>
        </v-card> -->

      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      :fullscreen="mobile"
      hide-overlay
      transition="fade-transition"
      @click:outside="close"
      style="overflow:auto;-webkit-overflow-scrolling:touch"
      width="800" >
      <template v-slot:activator="{ on }">
        <v-btn class="mx-3 ma-3 " color="black" fab v-on="on" elevation=0 :class="{mobile_input:mobile, desktop_input:!mobile}">
          <v-icon color="#FBDE44FF" x-large>add</v-icon>
        </v-btn>
      </template>
      <v-card class="pa-0 ma-0"  style="background-color:#f5f5f5;">

        <v-toolbar dark color="#FBDE44FF" v-if="mobile" class="mb-5">
          <v-btn icon color="black" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="black--text">활동 추가</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="black" text :disabled="!activity_selected || !editedItem.time"  @click="save2">저장 후 추가</v-btn>
            <!--<v-btn color="black" text @click="save">저장</v-btn>-->
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text no-gutters class="pb-0">

          <template class="overflow-y-auto" v-if="editedIndex">
            <v-card flat style="background-color:#f5f5f5;">
              <v-row no-gutters>
                <v-col cols="12" class="pt-5 pb-5">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="검색"
                    outlined
                    single-line
                    hide-details
                    class="dialog_search"
                    style="background-color:white;"
                  ></v-text-field>
                </v-col>

                <v-col sm="8" cols="12" style="background-color:white;border:1px solid #dddddd">
                  <v-data-table
                    class="dialog_table mt-5"
                    :headers="rawheaders"
                    :items="rawActivity"
                    :search="search"
                    :items-per-page=5
                    @click:row="selectRawActivity"
                    :mobile-breakpoint=NaN
                    dense
                    no-data-text="목록이 없습니다."
                    no-results-text="검색 결과가 없습니다."
                    :footer-props="{
                      showFirstLastPage: false,
                      // firstIcon: 'mdi-arrow-collapse-left',
                      // lastIcon: 'mdi-arrow-collapse-right',
                      // prevIcon: 'mdi-minus',
                      // nextIcon: 'mdi-plus',
                      itemsPerPageText: ''
                    }"
                  >
                    <template v-slot:item="{ item }">
                      <tr @click="selectRawActivity(item)">
                        <td>
                          <v-icon v-if="item._id" color="red" small>star</v-icon>
                          {{item.name}} <span class="caption" v-if="item._id">( {{item.time}} 시간 기준 )</span></td>
                        <td align=right>{{item.par}}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>

                <v-col sm="4" cols="12" class="ma-0 pa-0">
                  <v-row no-gutters>

                    <v-col sm="12" cols="6"  class="subtitle-2" align="center">
                      <div class="ml-2 pa-4 pt-2" style="background-color:white;border:1px solid #dddddd">
                        <div class="info_title">활동</div>
                        <div class="recommended2">{{ (editedItem.name == '')? '선택하세요':editedItem.name }}</div>
                        <div> {{ editedItem.text }}</div>
                      </div>
                    </v-col>

                    <v-col sm="12" cols="6" class="subtitle-2 big2">
                      <v-text-field
                        style="background-color:white;border:1px solid #dddddd"
                        v-model.number="editedItem.time"
                        :class="{ 'center-input':1, 'pa-4':1, 'pt-5': 1, 'ml-2': 1, 'mb-5': 1 }"
                        label="시간"
                        :type="mobile?'number':''"
                        required
                        hint="hours"
                        persistent-hint />
                    </v-col>

                    <!--
                    <v-col sm="12" cols="4" class="subtitle-2 pa-4 pt-0" align="center">
                      <div class="info_title">PAR</div>
                      <div class="recommended2">{{ editedItem.par }}</div>
                    </v-col>
                    -->

                  </v-row>
                </v-col>

              </v-row>
              <v-row dense>

                <v-col sm="12" cols="12" class="body-2 pa-3 pt-1 pb-1">
                  <div class="font-weight-bold" style="height:25px">안내</div>
                  <div style="line-height:1.8em">
                    - 표에서 활동을 선택하고 시간을 입력하세요.<br/>
                    - 활동강도 : 1시간 당 활동량 값입니다.<br/>
                    - 커스텀 활동은 <v-icon color="red" small>star</v-icon>표시가 붙어 있습니다.
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </template>

        </v-card-text>

        <v-card-actions v-if="!mobile" class="ma-4 mb-0 mt-3 pb-3 pt-0">
          <v-btn color="secondary" class="white--text" @click="close">취소</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="#FBDE44FF" class="black--text" :disabled="!activity_selected || !editedItem.time" @click="save2">저장 후 추가</v-btn>
          <!--<v-btn color="#FBDE44FF" class="black--text" @click="save">저장</v-btn>-->
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_template"
      :fullscreen="mobile"
      hide-overlay transition="fade-transition"
      @click:outside="close_template"
      width="800">
      <v-card class="pa-0 ma-0"  style="background-color:#f5f5f5;">

        <v-toolbar dark color="#FBDE44FF" v-if="mobile" class="mb-5">
          <v-btn icon color="black" @click="close_template">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="black--text">템플릿 저장</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="black" text @click="save_template">저장</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text no-gutters class="pb-0">
          <v-row  no-gutters>
            <v-col sm="12" cols="12" class="body-2 pa-3 pt-5 pb-1">
              <div class="font-weight-bold" style="height:25px">안내</div>
              <div style="line-height:1.8em">
                - 현재 활동으로 표시되는 내용을 템플릿으로 저장합니다.
              </div>
            </v-col>
          </v-row>
          <v-row  no-gutters>
            <v-col md="12" cols="12" class="subtitle-2 big2 pt-5 pb-5">
              <v-text-field
                v-model="defaultItem_template.name"
                @input = "change_template_name"
                label="템플릿명"
                outlined
                single-line
                hide-details
                style="background-color:white;"
                required />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions v-if="!mobile"  class="ma-4 mb-0 mt-3 pb-3 pt-0">
          <v-btn color="secondary" class="white--text" @click="close_template">취소</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="#FBDE44FF" class="black--text" :disabled="!template_selected" @click="save_template">저장</v-btn>
        </v-card-actions>


      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_template_shared"
      :fullscreen="mobile"
      hide-overlay transition="fade-transition"
      @click:outside="close_template_shared"
      width="800">
      <v-card class="pa-0 ma-0"  style="background-color:#f5f5f5;">

        <v-toolbar dark color="#FBDE44FF" v-if="mobile" class="mb-5">
          <v-btn icon color="black" @click="close_template_shared">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="black--text">템플릿 공유</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="black" text @click="share_template">공유</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text no-gutters class="pb-0">
          <v-row  no-gutters>
            <v-col sm="12" cols="12" class="body-2 pa-3 pt-5 pb-1">
              <div class="font-weight-bold" style="height:25px">안내</div>
              <div style="line-height:1.8em">
                - 현재 템플릿을 공유합니다.
              </div>
            </v-col>
          </v-row>
          <v-row  no-gutters>
            <v-col md="12" cols="12" class="subtitle-2 pt-5 pb-5">
              <v-text-field
                v-model="defaultItem_template_shared.name"
                @input = "change_template_shared_name"
                label="템플릿 공유 이름"
                outlined
                single-line
                hide-details
                style="background-color:white;"
                required />
            </v-col>
          </v-row>
          <v-row  no-gutters>
            <v-col md="12" cols="12" class="subtitle-2 pt-5 pb-5">
              <v-textarea
                v-model="defaultItem_template_shared.text"
                @input = "change_template_shared_text"
                label="템플릿 공유 설명"
                outlined
                hide-details
                style="background-color:white;"
                required />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions v-if="!mobile"  class="ma-4 mb-0 mt-3 pb-3 pt-0">
          <v-btn color="secondary" class="white--text" @click="close_template_shared">취소</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="#FBDE44FF" class="black--text" :disabled="!template_selected" @click="share_template">공유</v-btn>
        </v-card-actions>


      </v-card>
    </v-dialog>


  </div>
  <div v-else>
    <v-card flat class="pa-4 ma-3">
      기본 정보 및 활동 정보를 먼저 입력하세요.
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';
import {
  sleep,
  calc_food,
  calc_protein,
  get_basic_info,
  get_activity,
  post_activity,
  calc_tdee,
  get_custom_activity,
} from '../../common/functions.js';

import { mapGetters } from 'vuex';

import '../../common/common.js';

export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      description: `섭취량에 따라 기초대사량이 다르며, 사람마다 차이가 있습니다.<br/>
            칼로리는 추측값 입니다. 정확하게 하려고 무리할 필요 없습니다.<br/>
            같은 활동이라도 자세에 따라 다릅니다.<br/>
            운동을 2시간 넣는다면 '쉬지않고' 운동하는 개념입니다.<br/>
            이럴때는 '평균' 심박수를 이용하면 좋습니다.`,
      loading: true,
      show_desc: false,
      desc_calory: "린매스업: +-200<br/>벌크업: +200~300<br/>다이어트: -500~-700",
      activity_selected: false,
      activity_time: 0.0,
      search: "",
      dialog: false,
      dialog_temp: false,
      template_selected : false,

      shared_template_count: 0,
      shared_templates: [],
      shared_templates_mine: [],
      shared_templates_latest: [],
      shared_templates_popular: [],
      search_template_name:"",
      shared_templates_searched: [],
      template_shared_selected : false,
      selected_shared_template : {},

      dialog_guide: false,
      protein_multiplier: 1.6,

      headers: [
        {
          text: '활동',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: '시간', align: 'right', value: 'time', width:100, },
        { text: 'PAR', align: 'right', value: 'par', width:100, },
        { text: '', align: 'right', value: 'action', sortable: false, width:80, },
      ],
      rawheaders: [
        {
          text: '활동',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: '활동강도', align: 'right',  value: 'par', width: 100, },
      ],
      rawActivity: [
        { name: "잠자기", par: 0.93, },
        { name: "누워있기", par: 1.2, },
        { name: "읽기", par: 1.3, },
        { name: "앉아서 TV", par: 1.57, },
        { name: "사무업무", par: 1.6, },
        { name: "대중교통(앉음)", par: 1.72, },
        { name: "대중교통(서서)", par: 2, },
        { name: "식사", par: 1.4, },
        { name: "서서 돌아다니는 업무", par: 2.5, },
        { name: "주방일", par: 2.7, },
        { name: "느리게 걷기", par: 2.8, },
        { name: "집안일", par: 3.1, },
        { name: "빠르게 걷기", par: 3.8, },
        { name: "유산소(약)", par: 4.5, text:"살짝 숨차는 정도" },
        { name: "유산소(중)", par: 6, text:"코로 숨쉬기 힘든 정도" },
        { name: "유산소(강)", par: 9, text:"말하기 힘든 정도" },
        { name: "근력운동(약)", par: 3, text:"숨차지 않음" },
        { name: "근력운동(중)", par: 4.5, text:"살짝 숨차는 정도" },
        { name: "근력운동(강)", par: 6, text:"한세트 후 숨이 차는 강도" },
        { name: "운동 1", par: 4.4, },
        { name: "운동 2", par: 2.5, },
        { name: "운동 3", par: 2.2, },
        { name: "운동 4", par: 2.0, },
        { name: "운동 5", par: 1.9, },
      ],
      isEdit: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        time: 0.0,
        par: 0,
      },
      defaultItem: {
        name: '',
        time: 0.0,
        par: 0,
      },
      dialog_template: false,
      defaultItem_template: {
        name: '',
      },
      dialog_template_shared: false,
      defaultItem_template_shared: {
        name: '',
        text: '',
      },
      activity_templates: [],
      activity_templates_edit_flag: {},
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      data: "data",
      loaded: "loaded",
      date: "date",

      activity: "activity",
      base_calory: "base_calory",
      tdee: "tdee",

      dietstatus: "dietstatus",

      guide: "guide",
    }),
    getLoaded: function() {
      return this.$store.getters.loaded;
    },
    getDate: function() {
      return this.$store.getters.date;
    },
    getActivity: function() {
      return this.$store.getters.activity;
    },
    getGuide: function() {
      return this.$store.getters.guide;
    }
  },
  watch: {
    getLoaded: async function(v, oldv) {
      if ( v == true ) {
        await this.reload_init();
        await this.calc_calory();
      }
    },
    getDate: function() {
      this.reload_init();
    },
    getActivity: function() {
      this.reload_init();
    },
    getGuide: function() {
      console.log("guide : ", this.guide);
    }
  },
  mounted: async function() {
    await this.reload_init();
    await this.build_template_flag();

    await this.list_template_shared();
    await this.list_template_shared_mine();
    await this.list_template_shared_popular();

    await this.list_template_shared_latest();
    setTimeout(async ()=>{
      await this.list_template_shared_latest();
    }, 10000);

  },
  methods: {
    reload_init: async function() {
      this.rawActivity = [
        { name: "잠자기", par: 0.93, },
        { name: "누워있기", par: 1.2, },
        { name: "읽기", par: 1.3, },
        { name: "앉아서 TV", par: 1.57, },
        { name: "사무업무", par: 1.6, },
        { name: "대중교통(앉음)", par: 1.72, },
        { name: "대중교통(서서)", par: 2, },
        { name: "식사", par: 1.4, },
        { name: "서서 돌아다니는 업무", par: 2.5, },
        { name: "주방일", par: 2.7, },
        { name: "느리게 걷기", par: 2.8, },
        { name: "집안일", par: 3.1, },
        { name: "빠르게 걷기", par: 3.8, },
        { name: "유산소(약)", par: 4.5, text:"살짝 숨차는 정도" },
        { name: "유산소(중)", par: 6, text:"코로 숨쉬기 힘든 정도" },
        { name: "유산소(강)", par: 9, text:"말하기 힘든 정도" },
        { name: "근력운동(약)", par: 3, text:"숨차지 않음" },
        { name: "근력운동(중)", par: 4.5, text:"살짝 숨차는 정도" },
        { name: "근력운동(강)", par: 6, text:"한세트 후 숨이 차는 강도" },
        { name: "운동 1", par: 4.4, },
        { name: "운동 2", par: 2.5, },
        { name: "운동 3", par: 2.2, },
        { name: "운동 4", par: 2.0, },
        { name: "운동 5", par: 1.9, },
      ];

      const custom_activity = await get_custom_activity(this.user.data.email);
      if (custom_activity.length>0) {

        this.rawActivity.unshift(...custom_activity);
      }

      await this.get_templates();
      await this.calc_time();
      this.loading = false;
    },
    build_template_flag : async function() {
      for ( let each of this.activity_templates ) {
        this.$set(this.activity_templates_edit_flag, each._id, '');
      }
    },
    calc_time: async function() {

      this.activity_time = 0.0
      for ( let i in this.activity) {
        this.activity_time = this.activity_time + parseFloat(this.activity[i].time)
      }
    },
    calc_calory: async function() {

      // 활동 칼로리 계산
      const activity = await get_activity(this.user.data.email, this.date);
      if ( activity === undefined ) {
        await this.$store.dispatch('fetchActivity', []);
        await this.$store.dispatch('postTDEE', 0);
      } else {
        await this.$store.dispatch('fetchActivity', activity.activity);

        const [ basic_calory, calory_calculated ]= calc_tdee(this.data.weight, this.data.fat, activity.activity);
        await this.$store.dispatch('postBaseCalory', basic_calory);
        if ( calory_calculated < basic_calory )
          await this.$store.dispatch('postTDEE', basic_calory);
        else
          await this.$store.dispatch('postTDEE', calory_calculated);

      }

    },
    load_work1: async function() {
      if ( confirm('적용하시겠습니까?') ) {
        const t_ = [
          {
            name: "빈둥빈둥",
            time: 24,
            par: 1.4
          }
        ];

        await this.$store.dispatch('fetchActivity', t_);
        await post_activity(this.user.data.email, t_, this.date);

        this.calc_time();
        this.calc_calory();
      }
    },

    load_work2: async function() {
      if ( confirm('적용하시겠습니까?') ) {
        const t_ = [
          {
            name: "좌식업무",
            time: 24,
            par: 1.5
          }
        ];
        await this.$store.dispatch('fetchActivity', t_);

        await post_activity(this.user.data.email, t_, this.date);

        this.calc_time();
        this.calc_calory();
      }
    },

    load_work3: async function() {
      if ( confirm('적용하시겠습니까?') ) {
        const t_ = [
          {
            name: "돌아다니는 업무",
            time: 24,
            par: 1.75
          }
        ];
        await this.$store.dispatch('fetchActivity', t_);

        await post_activity(this.user.data.email, t_, this.date);

        this.calc_time();
        this.calc_calory();
      }
    },

    load_work4: async function() {
      if ( confirm('적용하시겠습니까?') ) {
        const t_ = [
          {
            name: "활동적인 업무",
            time: 24,
            par: 2
          }
        ];

        await this.$store.dispatch('fetchActivity', t_);
        await post_activity(this.user.data.email, t_, this.date);

        this.calc_time();
        this.calc_calory();
      }
    },

    load_work5: async function() {
      if ( confirm('적용하시겠습니까?') ) {
        const t_ = [
          { name: "잠자기", par: 0.93, time: 8 },
          { name: "사무업무", par: 1.6, time: 8 },
          { name: "식사", par: 1.4, time: 2 },
          { name: "대중교통(서서)", par: 2, time: 2 },
          { name: "근력운동(중)", par: 4.5, time: 1.5 },
          { name: "유산소(약)", par: 4.5, time: 0.5 },
          { name: "누워있기", par: 1.2, time: 1 },
          { name: "앉아서 TV", par: 1.57, time: 1 },
        ];

        await this.$store.dispatch('fetchActivity', t_);
        await post_activity(this.user.data.email, t_, this.date);

        this.calc_time();
        this.calc_calory();
      }
    },

    load_work6: async function() {
      if ( confirm('적용하시겠습니까?') ) {
        const t_ = [
          { name: "잠자기", par: 0.93, time: 9 },
          { name: "누워있기", par: 1.2, time: 3 },
          { name: "식사", par: 1.4, time: 2 },
          { name: "앉아서 TV", par: 1.57, time: 6 },
          { name: "근력운동(중)", par: 4.5, time: 1.5 },
          { name: "유산소(약)", par: 4.5, time: 0.5 },
          { name: "집안일", par: 3.1, time: 1 },
          { name: "느리게 걷기", par: 2.8, time: 1 },
        ];

        await this.$store.dispatch('fetchActivity', t_);
        await post_activity(this.user.data.email, t_, this.date);

        this.calc_time();
        this.calc_calory();
      }
    },

    get_templates: async function() {

      const id_token = await firebase.auth().currentUser.getIdToken();
      const templates = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity_template', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });
      if (templates.data.length>0) {
        this.activity_templates = templates.data;
      }
    },

    edit_template_name: async function(item) {

      if ( this.activity_templates_edit_flag[item._id] == '' )
        this.$set(this.activity_templates_edit_flag, item._id, item.name);
      else
        this.$set(this.activity_templates_edit_flag, item._id, '');

    },

    selectRawActivity: async function(v) {

      this.editedItem = this.$lodash.cloneDeep(v);
      this.activity_selected = true;

      this.editedItem.time = 1;
    },

    editItem: function (item) {
      this.editedIndex = this.activity.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.isEdit = true;
    },

    deleteItem: async function (index) {
      //const index = this.activity.indexOf(item)
      if ( confirm('삭제하시겠습니까?') ) {
        this.activity.splice(index, 1);
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity', {
          email: this.user.data.email,
          activity: this.activity,
        }, {
          headers: {'id_token': id_token},
        });

        this.$store.dispatch("fetchActivity", this.activity);

        this.calc_time();
        this.calc_calory();
      }
    },

    close: function () {
      this.dialog = false;
      this.activity_selected = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    close2: function () {
      this.activity_selected = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save: async function () {
      if ( this.editedItem.name == "" ) {
        alert("활동을 선택해주십시오.");
        return false;
      }

      if (this.editedIndex > -1) {
        Object.assign(this.activity[this.editedIndex], this.editedItem)
      } else {
        this.activity.push(this.editedItem)
      }

      await post_activity(this.user.data.email, this.activity, this.date);

      this.$store.dispatch("fetchActivity", this.activity);

      this.calc_time();
      this.calc_calory();

      this.isEdit = false;

      this.close();
    },

    save2: async function () {
      if ( this.editedItem.name == "" ) {
        alert("활동을 선택해주십시오.");
        return false;
      }

      if (this.editedIndex > -1) {
        Object.assign(this.activity[this.editedIndex], this.editedItem)
      } else {
        this.activity.push(this.editedItem)
      }

      await post_activity(this.user.data.email, this.activity, this.date);

      this.$store.dispatch("fetchActivity", this.activity);

      this.calc_time();
      this.calc_calory();

      this.isEdit = false;

      this.close2();

    },

    change_template_name: function() {
      if ( this.defaultItem_template.name == "" ) {
        this.template_selected = false;
      } else {
        this.template_selected = true;
      }
    },

    change_template_shared_name : function() {
      if ( this.defaultItem_template_shared.name == "" ) {
        this.template_selected = false;
      } else {
        this.template_selected = true;
      }
    },

    change_template_shared_text : function() {
      if ( this.defaultItem_template_shared.text == "" ) {
        this.template_selected = false;
      } else {
        this.template_selected = true;
      }
    },

    confirm_change_template_name: async function (item) {

      const name_ = this.activity_templates_edit_flag[item._id];

      for ( let each of this.activity_templates ) {
        if ( each._id == item._id ) {
          this.$set(each, "name", name_);
        }
      }

      this.$set(this.activity_templates_edit_flag, item._id, '');

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity_template/edit/name', {
        email: this.user.data.email,
        id: item._id,
        name: name_,
      }, {
        headers: {'id_token': id_token},
      });

    },

    close_template: function () {
      this.dialog_template = false;
      this.defaultItem_template.name = "";
      this.template_selected = false;
    },

    close_template_shared:  function() {
      this.dialog_template_shared = false;
      this.defaultItem_template_shared.name = "";
      this.defaultItem_template_shared.text = "";
      this.template_shared_selected = false;
      this.selected_shared_template = {};
    },

    save_template: async function () {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity_template', {
        email: this.user.data.email,
        name: this.defaultItem_template.name,
        activity: this.activity,
      }, {
        headers: {'id_token': id_token},
      });

      this.get_templates();

      this.calc_time();

      this.close_template();
    },

    delete_template: async function(item) {
      const index = this.activity_templates.indexOf(item)
      if ( confirm('삭제하시겠습니까?') ) {
        this.activity_templates.splice(index, 1);
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity_template/', {
          data: {
            email: this.user.data.email,
            template_id: item._id,
          },
          headers: {'id_token': id_token},
        });
        this.calc_time();
        this.calc_calory();
      }
    },

    share_template: async function() {

      let item_ = {
        ...this.selected_shared_template,
        title: this.defaultItem_template_shared.name,
        text: this.defaultItem_template_shared.text,
      };

      if ( confirm('공유하시겠습니까?') ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity_template/share/', {
          email: this.user.data.email,
          item: item_,
        },{
          headers: {'id_token': id_token},
        });

        await this.list_template_shared();
        await this.list_template_shared_mine();
        await this.list_template_shared_popular();
        await this.list_template_shared_latest();

        this.close_template_shared();
      }

    },

    search_template: async function() {
      const id_token = await firebase.auth().currentUser.getIdToken();
      const res_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity_template/share/search/', {
        params: { email: this.user.data.email, search: this.search_template_name },
        headers: {'id_token': id_token},
      });

      this.shared_templates_searched = res_.data;
    },

    unshare_template: async function(item) {
      if ( confirm('공유 제거 하시겠습니까?') ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity_template/unshare/', {
          email: this.user.data.email,
          item_id: item._id,
        },{
          headers: {'id_token': id_token},
        });

        await this.list_template_shared();
        await this.list_template_shared_mine();
        await this.list_template_shared_popular();
        await this.list_template_shared_latest();
      }
    },

    list_template_shared: async function() {

      const id_token = await firebase.auth().currentUser.getIdToken();
      const res_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity_template/share/', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      const res2_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity_template/share/count/', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      this.shared_template_count = res2_.data;
      this.shared_templates = res_.data;
    },

    list_template_shared_mine: async function() {

      const id_token = await firebase.auth().currentUser.getIdToken();
      const res_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity_template/share/mine/', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      this.shared_templates_mine = res_.data;
    },

    list_template_shared_latest: async function() {

      const id_token = await firebase.auth().currentUser.getIdToken();
      const res_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity_template/share/latest/', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      this.shared_templates_latest = res_.data;
    },

    list_template_shared_popular: async function() {

      const id_token = await firebase.auth().currentUser.getIdToken();
      const res_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity_template/share/popular/', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      this.shared_templates_popular = res_.data;
    },

    load_template: async function(item) {
      if ( confirm('적용하시겠습니까?') ) {

        await post_activity(this.user.data.email, item.activity, this.date);

        this.$store.dispatch("fetchActivity", item.activity);

        this.calc_time();
        this.calc_calory();
      }
    },

    import_template: async function(item) {

      let obj = this.activity_templates.find(o=>o.name===item.title);
      if ( obj !== undefined ) {
        alert('같은 이름이 존재합니다.');
        return
      }

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity_template/import/', {
        email: this.user.data.email,
        name: item.title,
        activity: item.activity,
        original_id: item._id,
      }, {
        headers: {'id_token': id_token},
      });

      this.get_templates();

    },

    onPressYesterday: async function() {
      await this.$store.dispatch('postDate', this.$moment(this.date,'YYYY-MM-DD').subtract(1, 'd').format('YYYY-MM-DD'));
    },
    onPressTomorrow: async function() {
      await this.$store.dispatch('postDate', this.$moment(this.date,'YYYY-MM-DD').add(1, 'd').format('YYYY-MM-DD'));
    },
    open_guide: async function() {

      if ( this.dietstatus == 1 ) {
        alert('다이어트를 종료 후 진행해주세요.');
        return;
      }

      this.dialog_guide = true;

      // 벌크업
      if ( this.guide == 3) {
        this.changeCaloriesDelta(200, false);
      }

      // 린매스업
      if ( this.guide == 4) {
        this.changeCaloriesDelta(0, false);
      }

      // 상승다이어트
      if ( this.guide == 6) {
        this.changeCaloriesDelta(100, false);
      }

      // 근육량증가
      if ( this.guide == 5) {
        this.changeCaloriesDelta(200, false);
      }

    },
    close_guide: async function() {
      this.dialog_guide = false;
    },
    changeProteinMultiplier: async function () {
      // console.log(this.protein_multiplier)
      await this.$store.dispatch('fetchProteinMultiplier', this.protein_multiplier);
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/history', {
        email: this.user.data.email,
        date: this.date,
        protein: this.protein_multiplier,
      }, {
        headers: {'id_token': id_token},
      });


      let gram_ = await calc_protein(this.user.sex, this.data.weight, this.data.fat, this.protein_multiplier);
      let kcal_ = this.data.food.protein.gram*4;

      const food_ = calc_food(this.tdee + this.data.calory_delta, {
        ...this.data.food,
        protein: {
          gram: gram_,
          kcal: kcal_,
        }
      })

      await this.$store.dispatch('fetchFood', food_);

      // await this.calc_macro_info();
      await this.draw_graph();
    },
    changeCaloriesDelta: async function(v, reload=false) {
      if ( Math.abs(v) > 1000 ) {
        alert('-1000 ~ 1000사이 값을 입력하세요.')
        return;
      }
      await this.$store.dispatch('fetchCaloryDelta', v);

      if ( reload ) {

        await this.reload_info();
        await this.draw_graph();
      }

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity', {
        email: this.user.data.email,
        date: this.date,
        calory_delta: Number(this.data.calory_delta),
      }, {
        headers: {'id_token': id_token},
      });

    },
    goto_guide: async function() {

      if ( this.guide == 1 || this.guide == 2 ) {
        this.$router.push('weight#diet');
      } else {
        this.$router.push('main#food');
      }

      this.dialog_guide=false;
    }
  }
}
</script>

<style scoped>

.theme--dark.v-divider {
    border-color: #FBDE44FF !important;
}

.op_btn {
  position:absolute;
  right:0;
  top:0;
}

.op_btn::before {
  color: transparent;
}

.op_btn::hover {
  color: transparent;
}

.v-btn:not(.v-btn--round).v-size--small {
  min-width: 0;
}

.edit_input >>> input {
  font-size: 14px !important;
  padding: 3px !important;

}

.edit_input >>> .v-input__slot {
  margin:0;
}

.edit_input >>> .v-messages__message {
  font-size: 9px !important;
  float: right !important;
  padding:0;
}

</style>