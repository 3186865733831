<template>
  <div class="basic">
    <v-row dense v-if="!loaded">
      <v-container fluid class="ma-10">
        <v-row dense>
          <v-col cols="12">
            <v-row class="align-center justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-col cols="12" align=center>
                기본 정보를 불러오고 있습니다.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row no-gutters  v-if="loaded">
      <v-col cols="12" class="ma-0 pa-3" style="background-color:#141E30;color:white;font-size:30px;font-weight:bold;text-align:center;">
        <span @click="onPressYesterday">&lt;</span>
        {{ date }}
        <span @click="onPressTomorrow">&gt;</span>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="loaded" align="center" justify="center" >
      <v-col sm="4" cols="4">
        <v-card flat class="big pa-4 ma-3 rounded-lg">
          <v-text-field
            v-model.number="weight"
            class="center-input"
            :type="mobile?'number':''"
            label="체중"
            required
            hint="kg"
            persistent-hint
            v-on:change="changeWeight"
            v-on:blur="changeWeight" />
        </v-card>
      </v-col>
      <v-col sm="4" cols="4">
        <v-card flat class="big pa-4 ma-3 rounded-lg">
          <v-text-field
            v-model.number="fat"
            class="center-input"
            label="체지방률"
            :type="mobile?'number':''"
            required
            hint="%"
            persistent-hint
            v-on:change="changeFat" />
        </v-card>
      </v-col>
      <v-col sm="4" cols="4">
        <v-card flat class="big pa-4 ma-3 rounded-lg">
          <v-text-field
            v-model.number="muscle"
            class="center-input"
            label="골격근량"
            :type="mobile?'number':''"
            required
            hint="kg"
            persistent-hint
            v-on:change="changeMuscle" />
        </v-card>
      </v-col>

      <v-col cols="12">

        <v-card flat class="pa-4 ma-3">

          <v-row dense class="mt-0">
            <v-col cols="2" class="ma-0 mb-5 pa-0">

            </v-col>
            <v-col v-if="user.sex == 'M'" cols="10" class="caption ma-0 mb-3 pa-0"  style="position:relative; background:transparent;">
              <div :style="{'position':'absolute', 'left':'0%'}" vertical-align="center">6</div>
              <div :style="{'position':'absolute', 'left':'20%'}">9.5</div>
              <div :style="{'position':'absolute', 'left':'40%'}">11.5</div>
              <div :style="{'position':'absolute', 'left':'60%'}">13.2</div>
              <div :style="{'position':'absolute', 'left':'80%'}">14.5</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'0%', 'width':'20%'}" align="center">매우낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'20%', 'width':'20%'}" align="center">낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'40%', 'width':'20%'}" align="center">보통</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'60%', 'width':'20%'}" align="center">높음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'80%', 'width':'20%'}" align="center">매우높음</div>
            </v-col>
            <v-col v-if="user.sex == 'F'" cols="10" class="caption ma-0 mb-3 pa-0"  style="position:relative; background:transparent;">
              <div :style="{'position':'absolute', 'left':'0%'}">4</div>
              <div :style="{'position':'absolute', 'left':'20%'}">7</div>
              <div :style="{'position':'absolute', 'left':'40%'}">8.6</div>
              <div :style="{'position':'absolute', 'left':'60%'}">9.8</div>
              <div :style="{'position':'absolute', 'left':'80%'}">11.5</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'0%', 'width':'20%'}" align="center">매우낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'20%', 'width':'20%'}" align="center">낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'40%', 'width':'20%'}" align="center">보통</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'60%', 'width':'20%'}" align="center">높음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'80%', 'width':'20%'}" align="center">매우높음</div>
            </v-col>
          </v-row>

          <v-row dense class="mt-1">
            <v-col cols="2" class="subtitle-2 font-weight-bold" align=center>
              골격근량
            </v-col>
            <v-col v-if="user.sex == 'M'" cols="10" class="caption pa-0" style="position:relative; background: rgb(214,57,57); background: linear-gradient(90deg, rgba(214,57,57,1) 0%, rgba(228,175,11,1) 50%, rgba(15,106,195,1) 100%);">
              <div :style="{'position':'absolute', 'left':'20%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'40%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'60%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'80%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div class="mt-0" :style="{'position':'relative', 'left':(muscle_v)+'%', 'border':'2px solid black', 'height':'100%', 'width':'2px'}"></div>
            </v-col>
            <v-col v-if="user.sex == 'F'" cols="10" class="caption pa-0" style="position:relative; background: rgb(214,57,57); background: linear-gradient(90deg, rgba(214,57,57,1) 0%, rgba(228,175,11,1) 50%, rgba(15,106,195,1) 100%);">
              <div :style="{'position':'absolute', 'left':'20%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'40%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'60%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'80%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div class="mt-0" :style="{'position':'relative', 'left':(muscle_v)+'%', 'border':'2px solid black', 'height':'100%', 'width':'2px'}"></div>
            </v-col>
          </v-row>

          <v-row dense class="mt-5">
            <v-col cols="2" class="ma-0 mb-5 pa-0">

            </v-col>
            <v-col v-if="user.sex == 'M'" cols="10" class="caption ma-0 mb-3 pa-0"  style="position:relative; background:transparent;">
              <div :style="{'position':'absolute', 'left':'0%'}">3%</div>
              <div :style="{'position':'absolute', 'left':'20%'}">8%</div>
              <div :style="{'position':'absolute', 'left':'40%'}">12%</div>
              <div :style="{'position':'absolute', 'left':'60%'}">18%</div>
              <div :style="{'position':'absolute', 'left':'80%'}">25%</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'0%', 'width':'20%'}" align="center">매우낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'20%', 'width':'20%'}" align="center">낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'40%', 'width':'20%'}" align="center">보통</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'60%', 'width':'20%'}" align="center">높음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'80%', 'width':'20%'}" align="center">매우높음</div>
            </v-col>
            <v-col v-if="user.sex == 'F'" cols="10" class="caption ma-0 mb-3 pa-0"  style="position:relative; background:transparent;">
              <div :style="{'position':'absolute', 'left':'0%'}">7%</div>
              <div :style="{'position':'absolute', 'left':'20%'}">10%</div>
              <div :style="{'position':'absolute', 'left':'40%'}">16%</div>
              <div :style="{'position':'absolute', 'left':'60%'}">24%</div>
              <div :style="{'position':'absolute', 'left':'80%'}">30%</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'0%', 'width':'20%'}" align="center">매우낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'20%', 'width':'20%'}" align="center">낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'40%', 'width':'20%'}" align="center">보통</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'60%', 'width':'20%'}" align="center">높음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'80%', 'width':'20%'}" align="center">매우높음</div>
            </v-col>
          </v-row>

          <v-row dense class="mt-1">
            <v-col cols="2" class="subtitle-2 font-weight-bold" align=center>
              체지방률
            </v-col>
            <v-col v-if="user.sex == 'M'" cols="10" class="caption pa-0"  style="position:relative; background: rgb(214,57,57); background: linear-gradient(270deg, rgba(214,57,57,1) 0%, rgba(228,175,11,1) 50%, rgba(15,106,195,1) 100%);">
              <div :style="{'position':'absolute', 'left':'20%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'40%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'60%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'80%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div class="mt-0" :style="{'position':'relative', 'left':(fat_v)+'%', 'border':'2px solid black', 'height':'100%', 'width':'2px'}"></div>
            </v-col>
            <v-col v-if="user.sex == 'F'" cols="10" class="caption pa-0"  style="position:relative; background: rgb(214,57,57); background: linear-gradient(270deg, rgba(214,57,57,1) 0%, rgba(228,175,11,1) 50%, rgba(15,106,195,1) 100%);">
              <div :style="{'position':'absolute', 'left':'20%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'40%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'60%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'80%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div class="mt-0" :style="{'position':'relative', 'left':(fat_v)+'%', 'border':'2px solid black', 'height':'100%', 'width':'2px'}"></div>
            </v-col>
          </v-row>
        </v-card>


        <v-card flat class="pa-4 ma-3">
          <v-card-title>추천 체중 관리</v-card-title>
          <v-card-text class="font-weight-bold" v-if="recommanded_y>=0">
            {{ recommanded_str }}
          </v-card-text>
        </v-card>
<!--
        <v-card flat class="body-2 pa-4 ma-3" style="border-radius:10px; border:4px solid #dddddd;">
          체지방율 20% 초과의 경우 20%로 환산. (여성의 경우 25%)<br/>
          체중당 섭취할 단백질은 초보자의 경우 2g/kg, 일반적으로 1.6g/kg 권장.<br/>
        </v-card> -->
      </v-col>

      <v-col cols="12">
        <v-card flat class="big pa-4 ma-3 mt-0 rounded-lg">
          <v-card-title>체중 관리 가이드</v-card-title>
          <v-card-text class="font-weight-bold">
            다음 버튼을 누르시면, 체중당 단백질, 칼로리 조정 값을 입력하실 수 있습니다.
          </v-card-text>
          <span style="color:red">
            <span v-if="recommanded_code.includes(2)">
              <v-btn color="#FBDE44FF" class="elevation-0 op_btn ma-2" @click="guide(2)" >
                다이어트
              </v-btn>
            </span>

            <span v-if="recommanded_code.includes(3)">
              <v-btn color="#FBDE44FF" class="elevation-0 op_btn ma-2" @click="guide(3)" >
                벌크업
              </v-btn>
            </span>

            <span v-if="recommanded_code.includes(1)">
              <v-btn color="#FBDE44FF" class="elevation-0 op_btn ma-2" @click="guide(1)" >
                커팅
              </v-btn>
            </span>

            <span v-if="recommanded_code.includes(4)">
              <v-btn color="#FBDE44FF" class="elevation-0 op_btn ma-2" @click="guide(4)" >
                린매스업
              </v-btn>
            </span>

            <span v-if="recommanded_code.includes(5)">
              <v-btn color="#FBDE44FF" class="elevation-0 op_btn ma-2" @click="guide(5)" >
                근육량 증가
              </v-btn>
            </span>

            <span v-if="recommanded_code.includes(6)">
              <v-btn color="#FBDE44FF" class="elevation-0 op_btn ma-2" @click="guide(6)" >
                상승 다이어트
              </v-btn>
            </span>

          </span>
          <!-- <v-btn depressed color="#141E30" large href="/main#activity" class="white--text">
            활동관리로 이동
          </v-btn> -->
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';
import { sleep, calc_food, calc_protein } from '../../common/functions.js';

import { mapGetters } from 'vuex';

let week = ['일', '월', '화', '수', '목', '금', '토'];

export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      muscle_v:0,
      fat_v:0,
      weight: 0,
      fat: 0,
      protein_multiplier: 1.6,
      muscle: 0,
      recommanded_str: "",
      recommanded_code: [0],
      recommanded_x: -1,
      recommanded_y: -1,
      // 굳 0, 커팅 1, 다이어트 2, 벌크업 3, 린매스업 4, 근육량 증가 5, 상승 6
      recommended_data_m: [
        [
          ['대회 준비가 아니라면, 오래 이 상태를 유지하지 않는 것이 좋습니다. 사람에 따라서 호르몬이 좋지 않게 유지될 수 있습니다.', [0]],
          ['커팅 추천',[1]],
          ['커팅 추천',[1]],
          ['다이어트 추천',[2]],
          ['다이어트 추천',[2]],
        ],
        [
          ['대회 준비가 아니라면, 오래 이 상태를 유지하지 않는 것이 좋습니다. 사람에 따라서 호르몬이 좋지 않게 유지될 수 있습니다.',[0]],
          ['커팅 추천',[1]],
          ['커팅 추천',[1]],
          ['다이어트 추천',[2]],
          ['다이어트 추천',[2]],
        ],
        [
          ['벌크업 추천',[3]],
          ['벌크업, 린매스업 추천',[3,4]],
          ['벌크업, 커팅 모두 가능. 단, 커팅 시 근육량이 낮아 근육 볼륨이 상당히 줄어들 수 있음. 상승다이어트 가능하나, 식단, 운동 노력이 상당히 필요.',[1,3]],
          ['다이어트 추천, 상승다이어트 가능하나, 식단, 운동 노력이 상당히 필요.',[2]],
          ['다이어트 추천, 상승다이어트 가능하나, 식단, 운동 노력이 상당히 필요.',[2]],
        ],
        [
          ['벌크업 추천',[3]],
          ['벌크업 추천',[3]],
          ['근육량 증가 추천',[5]],
          ['상승 다이어트 추천',[6]],
          ['상승 다이어트 추천',[6]],
        ],
        [
          ['벌크업 추천',[3]],
          ['벌크업 추천',[3]],
          ['근육량 증가 추천',[5]],
          ['상승 다이어트 추천',[6]],
          ['상승 다이어트 추천',[6]],
        ]
      ],
      recommended_data_f: [
        [
          ['대회 준비가 아니라면, 오래 이 상태를 유지하지 않는 것이 좋습니다. 사람에 따라서 호르몬이 좋지 않게 유지될 수 있습니다.',[0]],
          ['커팅 추천',[1]],
          ['커팅 추천',[1]],
          ['다이어트 추천',[2]],
          ['다이어트 추천',[2]],
        ],
        [
          ['대회 준비가 아니라면, 오래 이 상태를 유지하지 않는 것이 좋습니다. 사람에 따라서 호르몬이 좋지 않게 유지될 수 있습니다.',[0]],
          ['커팅 추천',[1]],
          ['커팅 추천',[1]],
          ['다이어트 추천',[2]],
          ['다이어트 추천',[2]],
        ],
        [
          ['벌크업 추천',[3]],
          ['벌크업, 린매스업 추천',[3,4]],
          ['벌크업, 커팅 모두 가능. 단, 커팅 시 근육량이 낮아 근육 볼륨이 상당히 줄어들 수 있음. 상승다이어트 가능하나, 식단, 운동 노력이 상당히 필요.',[3,1]],
          ['다이어트 추천, 상승다이어트 가능하나, 식단, 운동 노력이 상당히 필요.',[2]],
          ['다이어트 추천, 상승다이어트 가능하나, 식단, 운동 노력이 상당히 필요.',[2]],
        ],
        [
          ['벌크업 추천',[3]],
          ['벌크업 추천',[3]],
          ['근육량 증가 추천',[5]],
          ['린매스업 추천, 다이어트 하지 말 것!',[4]],
          ['린매스업 추천, 다이어트 하지 말 것!',[4]],
        ],
        [
          ['벌크업 추천',[3]],
          ['벌크업 추천',[3]],
          ['근육량 증가 추천',[5]],
          ['린매스업 추천, 다이어트 하지 말 것!',[4]],
          ['린매스업 추천, 다이어트 하지 말 것!',[4]],
        ]
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      limited: "limited",
      data: "data",
      loaded: "loaded",
      date: "date",
      height: "height",
      dietstatus: "dietstatus",
    }),
    getLoaded: function() {
      return this.$store.getters.loaded;
    },
    getWeight: function() {
      return this.$store.getters.data.weight;
    },
    getMuscle: function() {
      return this.$store.getters.data.muscle;
    },
    getFatrate: function() {
      return this.$store.getters.data.fat;
    },
    getHeight: function() {
      return this.$store.getters.height;
    },
    getProteinMultiplier: function() {
      return this.$store.getters.data.protein_multiplier;
    },
    getDate: function() {
      return this.$store.getters.date;
    },
  },
  watch: {
    getDate: async function(v, oldv) {
      this.reload_info();
    },
    getLoaded: async function(v, oldv) {
      if ( v == true ) {
        await this.reload_info(true);
      }
    },
    getWeight: function(v, oldv) {
      this.weight = v;
    },
    getMuscle: function(v, oldv) {
      this.muscle = v;
      this.reload_info(true);
    },
    getFatrate: function(v, oldv) {
      this.fat = v;
      this.reload_info(true);
    },
    getProteinMultiplier: function(v, oldv) {
      this.protein_multiplier = v;
    },
    getHeight: function(v, oldv) {
      this.reload_info();
    }
  },
  mounted: async function() {
    if ( this.loaded ) {
      this.reload_info(true);
    }
  },
  methods: {
    reload_info: async function(reloadall) {

      if ( reloadall ) {

        if ( this.weight != this.data.weight )
          this.weight = this.data.weight;
        if ( this.fat != this.data.fat )
          this.fat = this.data.fat;
        if ( this.muscle != this.data.muscle )
          this.muscle = this.data.muscle;
        if ( this.protein_multiplier != this.data.protein_multiplier )
          this.protein_multiplier = this.data.protein_multiplier;

      }

      let gram_ = await calc_protein(this.user.sex, this.weight, this.fat, this.protein_multiplier);
      let kcal_ = this.data.food.protein.gram*4;

      await this.$store.dispatch('fetchFood', {
        protein: {
          gram: gram_,
          kcal: kcal_,
        },
      });

      const height_ = (this.height/100);
      const value = Math.round((this.muscle/(height_*height_))*10)/10;

     if ( this.user.sex == 'M' ) {
        if ( value >= 14.5 ) {
          this.recommanded_y = 0;
          this.muscle_v = 80+( (value-14.5)/(18-14.5) * 100)/100*20;
        } else if ( value >= 13.2 ) {
          this.recommanded_y = 1;
          this.muscle_v = 60+( (value-13.2)/(14.5-13.2) * 100)/100*20;
        } else if ( value >= 11.5 ) {
          this.recommanded_y = 2;
          this.muscle_v = 40+( (value-11.5)/(13.2-11.5) * 100)/100*20;
        } else if ( value >= 9.5 ) {
          this.recommanded_y = 3;
          this.muscle_v = 20+( (value-9.5)/(11.5-9.5) * 100)/100*20;
        } else if ( value >= 6 ) {
          this.recommanded_y = 4;
          this.muscle_v = 0+( (value-6)/(9.5-6) * 100)/100*20;
        }

        if ( this.fat >= 25 ) {
          this.recommanded_x = 4;
          this.fat_v = 80+( (this.fat-25)/(35-25) * 100)/100*20;
        } else if ( this.fat >= 18 ) {
          this.recommanded_x = 3;
          this.fat_v = 60+( (this.fat-18)/(25-18) * 100)/100*20;
        } else if ( this.fat >= 12 ) {
          this.recommanded_x = 2;
          this.fat_v = 40+( (this.fat-12)/(18-12) * 100)/100*20;
        } else if ( this.fat >= 8 ) {
          this.recommanded_x = 1;
          this.fat_v = 20+( (this.fat-8)/(12-8) * 100)/100*20;
        } else if ( this.fat >= 3 ) {
          this.recommanded_x = 0;
          this.fat_v = 0+( (this.fat-3)/(8-3) * 100)/100*20;
        }
      } else {
        if ( value >= 11.5 ) {
          this.recommanded_y = 0;
          this.muscle_v = 80+( (value-11.5)/(15-11.5) * 100)/100*20;
        } else if ( value >= 9.8 ) {
          this.recommanded_y = 1;
          this.muscle_v = 60+( (value-9.8)/(11.5-9.8) * 100)/100*20;
        } else if ( value >= 8.6 ) {
          this.recommanded_y = 2;
          this.muscle_v = 40+( (value-8.6)/(9.8-8.6) * 100)/100*20;
        } else if ( value >= 7 ) {
          this.recommanded_y = 3;
          this.muscle_v = 20+( (value-7)/(8.6-7) * 100)/100*20;
        } else if ( value >= 4 ) {
          this.recommanded_y = 4;
          this.muscle_v = 0+( (value-4)/(7-4) * 100)/100*20;
        }

        if ( this.fat >= 30 ) {
          this.recommanded_x = 4;
          this.fat_v = 80+( (this.fat-30)/(40-30) * 100)/100*20;
        } else if ( this.fat >= 24 ) {
          this.recommanded_x = 3;
          this.fat_v = 60+( (this.fat-24)/(30-24) * 100)/100*20;
        } else if ( this.fat >= 16 ) {
          this.recommanded_x = 2;
          this.fat_v = 40+( (this.fat-16)/(24-16) * 100)/100*20;
        } else if ( this.fat >= 10 ) {
          this.recommanded_x = 1;
          this.fat_v = 20+( (this.fat-10)/(16-10) * 100)/100*20;
        } else if ( this.fat >= 7 ) {
          this.recommanded_x = 0;
          this.fat_v = 0+( (this.fat-7)/(10-7) * 100)/100*20;
        }
      }

      if ( this.recommanded_y > -1 && this.recommanded_x > -1 ) {

        this.recommanded_str = (this.user.sex == 'M') ?
          this.recommended_data_m[this.recommanded_y][this.recommanded_x][0] :
          this.recommended_data_f[this.recommanded_y][this.recommanded_x][0];
        this.recommanded_code = (this.user.sex == 'M') ?
          this.recommended_data_m[this.recommanded_y][this.recommanded_x][1] :
          this.recommended_data_f[this.recommanded_y][this.recommanded_x][1];
      }
    },
    changeWeight: async function() {
      if ( this.weight == "" || this.weight === null || this.weight === undefined ) {
        await this.$store.dispatch('fetchWeight', 0);
      } else {
        await this.$store.dispatch('fetchWeight', this.weight);
      }

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/history', {
        email: this.user.data.email,
        date: this.date,
        weight: this.weight,
      }, {
        headers: {'id_token': id_token},
      });

      await this.reload_info(false);
    },
    changeFat: async function() {
      if ( this.fat == "" || this.fat === null || this.fat === undefined ) {
        await this.$store.dispatch('fetchFat', 0);
      } else {
        await this.$store.dispatch('fetchFat', this.fat);
      }

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/history', {
        email: this.user.data.email,
        date: this.date,
        fat: this.fat,
      }, {
        headers: {'id_token': id_token},
      });

      await this.reload_info(false);
    },
    changeMuscle: async function(v) {
      if ( this.muscle == "" || this.muscle === null || this.muscle === undefined ) {
        await this.$store.dispatch('fetchMuscle', 0);
      } else {
        await this.$store.dispatch('fetchMuscle', this.muscle);
      }

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/history', {
        email: this.user.data.email,
        date: this.date,
        muscle: this.muscle,
      }, {
        headers: {'id_token': id_token},
      });

      await this.reload_info(false);
    },
    onPressYesterday: async function() {
      await this.$store.dispatch('postDate', this.$moment(this.date,'YYYY-MM-DD').subtract(1, 'd').format('YYYY-MM-DD'));
    },
    onPressTomorrow: async function() {
      await this.$store.dispatch('postDate', this.$moment(this.date,'YYYY-MM-DD').add(1, 'd').format('YYYY-MM-DD'));
    },
    guide: async function(value) {
      // 굳 0, 커팅 1, 다이어트 2, 벌크업 3, 린매스업 4, 근육량 증가 5, 상승 6
      if ( this.dietstatus == 1 ) {
        alert('다이어트를 종료 후 진행해주세요.');
        return;
      }
      if( confirm ('선택하시겠습니까?')) {
        await this.$store.dispatch('postGuide', value);
        this.$router.push('main#activity');
      }
    }
  }
}
</script>

<style scoped>

.minheight {
   min-height: 200px;
}

.vcenter {
  height: 20vh;
}

</style>
