<template>
  <div class="basic">
    <v-snackbar v-model="snackbar" :timeout="6000" color="error">
      {{ errmsg }}
    </v-snackbar>
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12">

        <v-card flat class="pt-3">
          <v-container fluid v-if="loading" class="ma-10">
            <v-row dense>
              <v-col cols="12">
                <v-row class="align-center justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </v-col>
              <v-col cols="12" align=center>
                그래프를 불러오고 있습니다.
              </v-col>
            </v-row>
          </v-container>

          <v-card flat class=" pa-4 ma-3">
            <canvas id="chart_" :class="{'mobile': mobile}"/>
          </v-card>

        </v-card>

        <v-card flat class="pa-4 ma-3">
          체중 추세 기울기 : {{ slope }} Kg<br/>
          <!-- 기록 시작 날짜 : {{ this.the_first_date }} -->
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-col cols="12" class="subtitle-2">
            그래프 시작일 설정
          </v-col>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="start_date"
                prepend-icon="event"
                label="날짜"
                readonly
                v-on="on" >
              </v-text-field>
            </template>
            <v-date-picker
              header-color="#FBDE44FF"
              locale="ko-kr"
              :day-format="getDayFormat"
              v-model="start_date"
              @input="menu2 = false"
              @change="pick_date2" />
          </v-menu>
        </v-card>
<!--
        <v-card flat class="pa-4 ma-3">
          <v-row dense class="pa-3">
            <v-col cols="12" class="subtitle-2">
              추세선 시작일 설정
            </v-col>
            <v-col cols="10">
              <v-menu
                v-model="linestart_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="linestart_day"
                    prepend-icon="event"
                    label="날짜"
                    readonly
                    v-on="on" >
                  </v-text-field>
                </template>
                <v-date-picker
                  header-color="#FBDE44FF"
                  locale="ko-kr"
                  :day-format="getDayFormat"
                  v-model="linestart_day"
                  @input="linestart_menu = false"
                  @change="linestart_pick_date"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2" class="pr-5">
              <v-btn block color="#FBDE44FF" class="ma-3" @click="set_linestart">설정</v-btn>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-row dense class="pa-3">
            <v-col cols="12" class="subtitle-2">
              예상선 시작일 설정
            </v-col>
            <v-col cols="10">
              <v-menu
                v-model="predictstart_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="predictstart_day"
                    prepend-icon="event"
                    label="날짜"
                    readonly
                    v-on="on" >
                  </v-text-field>
                </template>
                <v-date-picker
                  header-color="#FBDE44FF"
                  locale="ko-kr"
                  :day-format="getDayFormat"
                  v-model="predictstart_day"
                  @input="predictstart_menu = false"
                  @change="predictstart_pick_date"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2" class="pr-5">
              <v-btn block color="#FBDE44FF" class="ma-3" @click="set_predictstart">설정</v-btn>
            </v-col>
          </v-row>
        </v-card> -->

        <v-card flat class="pa-4 ma-3">
          <v-row dense class="pa-3">
            <v-col cols="12" class="subtitle-2">
              개별 입력
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="today_date"
                    prepend-icon="event"
                    label="날짜"
                    readonly
                    v-on="on" >
                  </v-text-field>
                </template>
                <v-date-picker
                  header-color="#FBDE44FF"
                  locale="ko-kr"
                  :day-format="getDayFormat"
                  v-model="today_date"
                  @input="menu = false"
                  @change="pick_date"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
                <v-text-field v-model="my_weight" label="몸무게"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-btn color="#FBDE44FF" class="ma-3" @click="enter_each_input">저장</v-btn>
            </v-col>
            <v-col cols="12">
              이미 값이 존재하는 경우, 새 값으로 갱신됩니다.
            </v-col>
            <v-col cols="12" align="right">
              <v-btn color="red" class="ma-3" @click="delete_input">선택한 날짜의 데이터 삭제</v-btn>
            </v-col>
          </v-row>
        </v-card>
<!--
        <v-card flat  class="pa-4 ma-3">
          <v-row dense class="pa-3">
            <v-col cols="4" class="subtitle-2">
              XLSX 가져오기
            </v-col>
            <v-col cols="5">
              <v-file-input label="파일 업로드" v-model="file" @change="onChange"></v-file-input>
            </v-col>
            <v-col cols="3" class="pr-5 mb-9">
              <v-btn block color="#FBDE44FF" class="ma-3" @click="upload_excel">업로드</v-btn>
            </v-col>
            <v-col cols="4" class="subtitle-2">
              XLSX 내보내기
            </v-col>
            <v-col cols="8" class="pr-5">
              <v-btn block color="#FBDE44FF" class="ma-3" @click="download_excel">
                다운로드
              </v-btn>
            </v-col>
          </v-row>
        </v-card> -->

      </v-col>
    </v-row>

    <v-dialog v-model="graph_dialog" width="800" :fullscreen="mobile">
      <v-card class="ma-0 pa-0">
        <v-toolbar dark color="#FBDE44FF">
          <v-btn icon color="black" @click="graph_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="black--text">{{ graph_pick_date }} 식단</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <template>
            <v-col md="12" cols="12" v-for="(meal, id) in foods_refined" :key="id" >
              <v-card flat class="ma-0" color="grey lighten-4">
                <v-card-title class="pa-2 pt-0 pb-0 title">
                  <v-row dense>
                    <v-col cols="4">
                      {{ meal.name }}
                    </v-col>
                    <v-col cols="2" class="ma-0 mt-1 pa-0">
                      <div class="subtitle-2 mt-1">
                        {{ meal.kcal.format() }}Kcal
                      </div>
                    </v-col>
                    <v-col cols="2" align="center" class="ma-0 mt-1  pa-0">
                      <div class="subtitle-2 mt-1" style="border-bottom: 2px solid #70AD47">
                        {{ meal.carb.format() }}g
                      </div>
                    </v-col>
                    <v-col cols="2" align="center" class="ma-0 mt-1  pa-0">
                      <div class="subtitle-2 mt-1" style="border-bottom: 2px solid #4472C7">
                        {{ meal.protein.format() }}g
                      </div>
                    </v-col>
                    <v-col cols="2" align="center" class="ma-0 mt-1  pa-0">
                      <div class="subtitle-2 mt-1" style="border-bottom: 2px solid #ED7D31">
                        {{ meal.fat.format() }}g
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-row dense>
                  <v-col md="6" cols="12" v-for="(each, i) in meal.list" :key="i">
                    <v-card outlined class="elevation-0 pa-3 pt-1 pb-1 ma-3 mt-0 mb-0">
                      <v-row dense>
                        <v-col cols="12" class="subtitle-2">
                          <v-row dense>
                            <v-col cols="12">
                              {{ each.name }} x {{ each.amount }}
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" class="caption">{{ (Math.round(each.calory*each.amount*10)/10) }}Kcal</v-col>
                        <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #70AD47">{{ (Math.round(each.carb*each.amount*10)/10) }}g</v-col>
                        <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #4472C7">{{ (Math.round(each.protein*each.amount*10)/10) }}g</v-col>
                        <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #ED7D31">{{ (Math.round(each.fat*each.amount*10)/10) }}g</v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import lodash from 'lodash';

import {DateTime} from 'luxon'

import { mapGetters } from 'vuex';

const average = (array) => array.reduce((a, b) => a + b) / array.length;


function round(num) {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return Math.round(m) / 100 * Math.sign(num);
}


const linest = function(y, x) {
  let linest = 0;

  if ( y.length == x.length ) {
    let avgY = average(y);
    let avgX = average(x);
    let dividend = [];
    let divisor = [];
    for ( let i = 0; i < y.length; i++ ) {
      dividend[i] = (x[i] - avgX) * (y[i] - avgY);
      divisor[i] = Math.pow((x[i] - avgX), 2);
    }
    linest = dividend.reduce((p,n) => (p+n)) / divisor.reduce((p,n) => (p+n))
  }
  return linest;
}

let moment;

export default {
  components: {
  },
  props: {
    msg: String
  },
  data: () => {
    return {
      linestart_day: "",
      linestart_menu: false,
      predictstart_day: "",
      predictstart_menu: false,
      start_date: "",
      today_date: "",
      the_first_date: "",
      snackbar: false,
      errmsg: "",
      excel_dump: false,
      each_input: false,
      menu: false,
      menu2: false,
      menu3: false,
      my_weight: 0,
      chart: null,
      desc_weight: "조절 칼로리 양을 기반으로 </br>예측한 선형 체중 변화량",
      loading: true,
      weight_history: [],
      data1: [],
      data2: [],
      data3: [],
      label_: [],
      slope: 0,
      file: null,
      selectedSheet: 'Sheet1',
      imported_data: [],
      export_data: [],

      foods_refined: {
        breakfast: {
          name: '아침',
          list: [],
          kcal: 0,
          carb: 0,
          protein: 0,
          fat: 0,
        },
        lunch: {
          name: '점심',
          list: [],
          kcal: 0,
          carb: 0,
          protein: 0,
          fat: 0,
        },
        dinner: {
          name: '저녁',
          list: [],
          kcal: 0,
          carb: 0,
          protein: 0,
          fat: 0,
        },
        snack: {
          name: '간식',
          list: [],
          kcal: 0,
          carb: 0,
          protein: 0,
          fat: 0,
        },
      },

      graph_pick_date:"",
      graph_dialog: false,
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      data: "data",
      mobile: "mobile",
      loaded: "loaded",

    }),
    getLoaded: function() {
      return this.$store.getters.loaded;
    },
    getWeight: function() {
      return this.$store.getters.data.weight;
    }
  },
  watch: {
    getLoaded: async function(v, oldv) {
      if ( v == true ) {
        await this.draw_graph();
      }
    },
    getWeight: function() {
      setTimeout(this.draw_graph, 1000);
    }
  },
  created: function() {
    moment = this.$moment;
  },
  mounted: async function() {
    if ( this.loaded ) {
      await this.draw_graph();
    }

    this.today_date = this.$moment().format("YYYY-MM-DD");
    this.end_date = this.$moment().format("YYYY-MM-DD");
    this.start_date = this.$moment().subtract(1, 'months').format("YYYY-MM-DD");

    // this.$nextTick(async function() {
    //   //moment = this.$moment;

    //   this.draw_graph();

    //   this.today_date = this.$moment().format("YYYY-MM-DD");
    //   this.end_date = this.$moment().format("YYYY-MM-DD");
    //   this.start_date = this.$moment().subtract(1, 'months').format("YYYY-MM-DD");

    // });
  },
  methods: {
    getDayFormat: function(date) {
      return this.$moment(date, "YYYY-MM-DD").format("D");
    },
    draw_graph: async function() {
      if ( this.data1.length > 0 ) {
        this.data1 = [];
        this.label_ = [];
      }
      if ( this.chart !== null ){
        this.chart.destroy();
      }

      if ( firebase.auth().currentUser ) {

        const id_token = await firebase.auth().currentUser.getIdToken();
        // 개인정보 조회
        const _user = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/one/', {
          params: { email: this.user.data.email},
          headers: {'id_token': id_token},
        });

        this.start_date = _user.data.graphstartday;

        // // 다이어트 기간이 그래프 범위에 낀 경우
        let _raw_diet = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/diet/range/', {
          params: { email: this.user.data.email, date:this.start_date },
          headers: {'id_token': id_token},
        });

        // 다이어트 기간이 그래프에 포함된 경우
        const _raw_diet2 = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/diet/range2/', {
          params: { email: this.user.data.email, date:this.start_date },
          headers: {'id_token': id_token},
        });

        //합치기
        _raw_diet = _raw_diet.data.concat(_raw_diet2.data)

        // 다이어트 배열 빌딩
        let diet_array = {};

        // 다이어트 배열 길이가 0이 아닌경우
        if ( _raw_diet.length > 0 ) {
          // 루프
          for ( const each_diet of _raw_diet ) {
            // 날짜가 없으면 넘어감
            if ( each_diet.date === null ) continue;

            const each_diet_start = DateTime.fromISO(each_diet.date);
            let each_diet_finish = null;

            // 다이어트가 진행중인 경우 date_f값이 없다. each_diet_finish 값은 종료 날짜 계산
            if ( each_diet.date_f == "" ) {
              // 다이어트가 현재 진행중인 경우 다이어트 기간 만큼은 추가로 예측 선에서 줄여줘야 한다.
              let weight_init = each_diet.weight;
              let fatrate_init = each_diet.fatrate;
              let fatrate_want = each_diet.fatrate_want;
              let calory_delta = each_diet.calory_delta_init;

              let weight_target = Math.round((weight_init * ((100 - fatrate_init)/100)/((100 - fatrate_want)/100))/100)*100;
              let weight_delta = Math.round((weight_init - weight_target)/100)*100;

              // 이것이 바로 다이어트 날 수,
              let day_diet = Math.round((weight_delta*7000/calory_delta)/100)*100;

              each_diet_finish = DateTime.fromISO(each_diet.date).plus({days:day_diet});
            } else {
              each_diet_finish = DateTime.fromISO(each_diet.date_f);
            }

            //if ( each_diet_start.format("YYYY-MM-DD") < this.predictstart_day ) continue;

            let week = 0;
            for ( let i = lodash.cloneDeep(each_diet_start) ; i <= each_diet_finish ; i = i.plus({days:1}) ) {
              // 치팅 데이가 껴 있으면, 일주일 뒤로 미룸
              for ( const each_cheat of _raw_diet ) {
                if ( each_cheat.date == i.toFormat("YYYY-MM-DD") ) {
                  let j = 0;
                  while ( j < 7 ) {
                    if ( i.toFormat("yyyy-MM-dd") > each_diet_finish.toFormat("yyyy-MM-dd") ) {
                      break;
                    }
                    diet_array[i.toFormat("yyyy-MM-dd")] = 0;
                    i = i.plus({days:1});
                    j++;
                  }
                  if ( i.toFormat("yyyy-MM-dd") > each_diet_finish.toFormat("yyyy-MM-dd") ) {
                    break;
                  }
                  each_diet_finish = each_diet_finish.plus({weeks:1});
                }
              }

              // console.log(each_diet, each_diet.calory_delta_init, each_diet.calory_delta_weekly, week)

              diet_array[i.toFormat("yyyy-MM-dd")] = each_diet.calory_delta_init; // + each_diet.calory_delta_weekly*week;
              week = Math.floor(i.diff(each_diet_start, ['days']).days/ 7);
            }
          }

        }

        // 예상 체중 그리기
        if ( diet_array === undefined ) return;

        let _raw_data = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/history/from/', {
          params: { email: this.user.data.email, date: this.start_date },
          headers: {'id_token': id_token},
        });

        // 예상 체중
        if ( _raw_data.data.length > 0) {

          _raw_data = _raw_data.data.filter((v)=>v.weight!==0);

          // 활동 데이터에서 칼로리 델타 정리
          const _calory_delta = await axios.get(
            api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity/from/', {
            params: { email: this.user.data.email, date: this.start_date },
            headers: {'id_token': id_token},
          });

          // 그래프 시작일 기준으로 최근 값
          const _calory_delta_zero = await axios.get(
            api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity/latestbydate/', {
            params: { email: this.user.data.email, date: this.start_datey },
            headers: {'id_token': id_token},
          });

          let _calory_delta_start_day = DateTime.fromISO(this.start_date);
          if ( _calory_delta_zero.data[0] !== undefined ) {
            _calory_delta_start_day = DateTime.fromISO(_calory_delta_zero.data[0].date);
          }
          let _calory_delta_finish_day = DateTime.now().plus({ days: 30 });

          let _calory_delta_refined = {};
          for ( let each_ of _calory_delta_zero.data) {
            _calory_delta_refined[each_.date] = each_.calory_delta;
          }

          for ( let each_ of _calory_delta.data) {
            _calory_delta_refined[each_.date] = each_.calory_delta;
          }

          const _diff_day_for_calory_delta = _calory_delta_finish_day.diff(_calory_delta_start_day, ['days']).days;
          let _prev_calory_delta = (_calory_delta_zero &&  _calory_delta_zero.data[0] && _calory_delta_zero.data[0].calory_delta)?_calory_delta_zero.data[0].calory_delta:0;
          for ( let _s = 0 ; _s <= _diff_day_for_calory_delta ; _s++ ) {
            const __date = _calory_delta_start_day.plus({ days: _s }).toFormat("yyyy-MM-dd");
            if ( _calory_delta_refined[__date] === undefined ) {
              _calory_delta_refined[__date] = _prev_calory_delta;
            } else {
              _prev_calory_delta = _calory_delta_refined[__date];
            }
          }

          console.log('다이어트 정보 값',diet_array)
          console.log('칼로리 증감 값',_calory_delta_refined)
          // 칼로리 증감 데이터 구축 완료

          // 예측 데이터
          let data2_ = [];
          {
            // 그래프 시작 날과 예측 선 첫 날의 차이에 따라 빈 값 추가
            // let i = 0;
            // let the_first_day = DateTime.fromISO(user.graphstartday); // 지정된 그래프 시작일
            // let the_last_day = DateTime.fromISO(_raw_data[_raw_data.length-1].date);

            // let the_predict_day = DateTime.fromISO(user.predict_date);

            let s_day = _raw_data[0];
            // if ( the_first_day <= the_predict_day ) {
            //   // 예측선 시작일이 그래프 시작일 보다 이후인 경우에는 그 날짜 이후부터 시작
            //   s_day = _raw_data.filter((v)=>v.date>=the_predict_day)[0];
            // }

            let prev_day = s_day.date;
            let calory_accumulation = 0;
            for ( let each of _raw_data ) {
              // 시작일 이전 값은 패스
              if ( each.date < s_day.date ) continue;

              // 현재 값과 이전 값의 날짜 차이
              const _diff_day = DateTime.fromISO(each.date).diff(DateTime.fromISO(prev_day), ['days']).days;

              for ( let _s = 0 ; _s < _diff_day ; _s++ ) {
                // 해당 날짜의 다이어트 값이 있다면,
                if ( diet_array[DateTime.fromISO(prev_day).plus({ days: _s }).toFormat("yyyy-MM-dd")] !== undefined ) {
                  calory_accumulation-=diet_array[DateTime.fromISO(prev_day).plus({ days: _s }).toFormat("yyyy-MM-dd")];
                } else {
                  // 다이어트 값이 없다면, 칼로리 증감 값 가져옴
                  calory_accumulation+=_calory_delta_refined[DateTime.fromISO(prev_day).plus({ days: _s }).toFormat("yyyy-MM-dd")];
                }

              }

              let t = Math.round((s_day.weight + calory_accumulation/7000)*100)/100;
              data2_.push(t);

              prev_day = each.date;
            }

          }

          this.data2 = data2_;

          let data_ = [];
          let label_ = [];

          for ( const each of _raw_data ) {

            data_.push(each.weight);

            label_.push(each.date)
          }

          this.data1=data_;
          this.label_=label_;

          let tmp_data_ = [];
          let tmp_date_ = [];
          let i = 0;
          let prev_weight = 0;
          for ( let each of _raw_data) {
            if ( each.date < this.start_date ) continue;
            tmp_date_.push(i);
            tmp_data_.push((each.weight === null || each.weight === undefined || each.weight == 0) ? prev_weight: each.weight);
            i++;
            if ( each.weight !== null && each.weight !== undefined && each.weight !== 0 )
              prev_weight = each.weight;
          }

          if ( tmp_data_&& tmp_data_.length > 0 ) {

            let data3_raw = [];

            let t2 = linest(tmp_data_, tmp_date_);
            let b = (average(tmp_data_) - t2*average(tmp_date_));

            let offset = t2;

            let j = 0;
            for ( let each of _raw_data) {
              if ( each.date < this.start_date ) continue;

              if ( j == 0 ) {
                // data3_raw.push({
                //   date: each.date,
                //   weight: b ? b:data_[0].weight,
                // });
                data3_raw.push(b ? b:data_[0].weight);
              } else {
                // data3_raw.push({
                //   date: each.date,
                //   weight: (b+offset*j),
                // });
                data3_raw.push(b+offset*j);
              }
              j++;
            }

            this.data3=data3_raw;

            this.slope = Math.round(t2*1000)/1000;
            // setSlope(Math.round(t2*1000)/1000);

          }

          // console.log('data3', this.data3);

          {

            this.loading = false;
            const ori_this = this;

            const chart_ctx = document.getElementById('chart_').getContext('2d');
            this.chart = new this.$Chart(chart_ctx, {
              type: 'line',
              data: {
                labels: this.label_,
                datasets: [
                  {
                    label: '실제 체중',
                    data: this.data1,
                    tension:0,
                    borderColor: [
                      'rgba(12, 123, 147, 1)',
                    ],
                    fill: false,
                    pointBorderColor: 'rgba(12, 123, 147, 0.5)',
                    borderWidth: 2,
                    hoverBorderWidth: 10,
                    pointRadius: 5,
                    pointBackgroundColor: "rgba(255,255,255,1)",
                  },
                  {
                    label: '예상 체중',
                    data: this.data2,
                    borderColor: [
                      '#cccccc',
                    ],
                    tension:0,
                    fill: false,
                    pointBorderColor: '#cccccc',
                    borderWidth: 2,
                    pointRadius: 1.5,
                    pointBackgroundColor: "rgba(255,255,255,1)",
                  },
                  {
                    label: '체중 추세',
                    data: this.data3,
                    borderColor: [
                      'rgba(235, 146, 52, 1)',
                    ],
                    fill: false,
                    tension:0,
                    pointBorderColor: 'rgba(235, 146, 52, 1)',
                    borderWidth: 2,
                    pointRadius: 1.5,
                    pointBackgroundColor: "rgba(255,255,255,1)",
                  }
                ],
              },
              options: {
                'onClick': async (evt, item) => {
                  //this.graph_pick_food_list = [];
                  if ( item[0] != undefined ) {
                    ori_this.graph_pick_date = ori_this.label_[item[0]._index];
                    const id_token = await firebase.auth().currentUser.getIdToken();
                    const food_history = await axios.get(
                      api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/foodhistory/', {
                      params: { email: ori_this.user.data.email, date: ori_this.graph_pick_date },
                      headers: {'id_token': id_token},
                    });

                    if ( food_history.data.length > 0 ) {

                      for ( let [key, meal] of Object.entries(ori_this.foods_refined) ) {
                        ori_this.foods_refined[""+key+""].list = [];
                        ori_this.foods_refined[""+key+""].kcal = 0;
                        ori_this.foods_refined[""+key+""].carb = 0;
                        ori_this.foods_refined[""+key+""].protein = 0;
                        ori_this.foods_refined[""+key+""].fat = 0;
                      }

                      let arr = ['t', 'breakfast', 'lunch', 'dinner', 'snack'];

                      for ( let each of food_history.data[0].foodlist ) {
                        ori_this.foods_refined[arr[each.meal]].list.push(each);
                        ori_this.foods_refined[arr[each.meal]].kcal += each.calory * each.amount;
                        ori_this.foods_refined[arr[each.meal]].carb += each.carb * each.amount;
                        ori_this.foods_refined[arr[each.meal]].protein += each.protein * each.amount;
                        ori_this.foods_refined[arr[each.meal]].fat += each.fat * each.amount;
                        ori_this.foods_refined[arr[each.meal]].kcal = Math.round(this.foods_refined[arr[each.meal]].kcal*100)/100;
                        ori_this.foods_refined[arr[each.meal]].carb = Math.round(this.foods_refined[arr[each.meal]].carb*100)/100;
                        ori_this.foods_refined[arr[each.meal]].protein = Math.round(this.foods_refined[arr[each.meal]].protein*100)/100;
                        ori_this.foods_refined[arr[each.meal]].fat = Math.round(this.foods_refined[arr[each.meal]].fat*100)/100;
                      }

                      //this.graph_pick_food_list = this.food_list[ori_this.graph_pick_date];
                      ori_this.graph_dialog = true;
                    }

                  }
                },
                animation: {
                  duration: 0,
                },
                responsive: true,
                maintainAspectRatio: false, // default value. false일 경우 포함된 div의 크기에 맞춰서 그려짐.
                legend: {
                  position: "bottom",
                  labels: {
                    //fontColor: 'white',
                  }
                },
                tooltips: {
                  mode: 'index',
                  intersect: false,
                  callbacks: {
                    label: function(tooltipItem, data) {
                      return data.datasets[tooltipItem.datasetIndex].label + ":" + Math.round(tooltipItem.yLabel*100)/100;
                    }
                  }
                },
                scales: {
                  yAxes: [{
                      ticks: {
                          beginAtZero:false,

                          //fontColor: 'white',
                          callback: function(value, index, values) {
                            return Math.round(value*100)/100;
                          }
                      },
                      gridLines: {
                        display: true,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: 'Kg',
                        // fontColor: 'white',
                      }
                  }],
                  xAxes: [{
                    ticks: {
                      beginAtZero:false,
                      autoskip: true,
                      autoSkipPadding: 50,
                      maxRotation: 0,
                      minRotation: 0,
                      // fontColor: 'white',
                      callback: function(value, index, values) {
                        return moment(value).format("YY/MM/DD");
                      }

                    },
                    gridLines: {
                      display: false,
                    },
                  }],
                },
                plugins: {
                  datalabels: {
                    display: false,

                  }
                },

              },
            });
          }

        }

      }
    },
    onChange(event) {
      //console.log(this.file)
    },
    pick_date() {
      let temp_weight = 0
      for ( let each in this.weight_history ) {
        if ( this.today_date == this.weight_history[each].date ) {
          temp_weight = this.weight_history[each].weight;
        }
      }
      this.my_weight = temp_weight;
    },
    linestart_pick_date: async function() {
      //
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/linestart/', {
        email: this.user.data.email,
        linestart: this.linestart_day,
      }, {
        headers: {'id_token': id_token},
      });

      //
      this.draw_graph();
    },
    predictstart_pick_date: async function() {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/predictstart/', {
        email: this.user.data.email,
        predictstart: this.predictstart_day,
      }, {
        headers: {'id_token': id_token},
      });

      this.draw_graph();
    },
    pick_date2: async function() {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/graphstart/', {
        email: this.user.data.email,
        graphstart: this.start_date,
      }, {
        headers: {'id_token': id_token},
      });

      this.draw_graph();
    },
    pick_date3() {
      this.draw_graph();
    },
    /*
    change_status(whichone) {
      if ( whichone == 'excel_dump' ) {
        this.excel_dump = !this.excel_dump
      } else if ( whichone == 'each_input' ) {
        this.each_input = !this.each_input
      }
      if ( !this.excel_dump ) {
        this.imported_data = []
        this.exported_data = []
        this.file = null
      }
      if ( !this.each_input ) {
        this.today_date = this.$moment().format("YYYY-MM-DD");
        this.my_weight = 0
      }
    },
    */
    async upload_excel(e) {
      if ( !this.file ) {
        return alert('파일을 선택해 주십시오');
      }
      let f = this.file
      let reader = new FileReader()
      let obj = this;
      reader.onload = async function(e) {
        let data = new Uint8Array(e.target.result)
        let workbook = obj.$XLSX.read(data, {type: 'array'})
        let sheetName = workbook.SheetNames[0]
        let worksheet = workbook.Sheets[sheetName]
        let json_worksheet = obj.$XLSX.utils.sheet_to_json(worksheet)

        for ( let each in json_worksheet) {
          let temp = {date: '', weight: 0}
          if ( typeof(json_worksheet[each]['날짜']) == 'number' ) {
            temp.date = moment((json_worksheet[each]['날짜'] - (25567 + 2))*86400*1000).format("YYYY-MM-DD")
          } else {
            temp.date = json_worksheet[each]['날짜']
          }
          temp.weight = json_worksheet[each]['몸무게']
          obj.imported_data.push(temp)
        }

        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/history/import', {
          email: obj.user.data.email,
          data: obj.imported_data
        }, {
          headers: {'id_token': id_token},
        })
      }
      reader.readAsArrayBuffer(f);
      //console.log(this.imported_data);

      this.draw_graph()
      this.file = null
      this.imported_data = []
    },
    async download_excel(e) {
      let temp_array = ['날짜', '몸무게']
      this.export_data.push(temp_array)
      for ( let each of this.weight_history ) {
        temp_array = []
        temp_array.push(each.date)
        temp_array.push(each.weight)
        this.export_data.push(temp_array)
      }
      let wb = this.$XLSX.utils.book_new()
      let newWorksheet = this.$XLSX.utils.aoa_to_sheet(this.export_data)
      this.$XLSX.utils.book_append_sheet(wb, newWorksheet, 'Sheet1')
      let download_filename = 'weight-history('+this.user.data.email+').xlsx'
      this.$XLSX.writeFile(wb, download_filename)

      this.export_data = []
    },
    async enter_each_input() {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/history', {
        email: this.user.data.email,
        date: this.today_date,
        weight: this.my_weight
      }, {
        headers: {'id_token': id_token},
      });

      this.today_date = this.$moment().format("YYYY-MM-DD");
      this.my_weight = 0
      this.draw_graph()
    },
    async delete_input() {
      if ( !confirm("삭제하더라도 그래프는 전날 데이터로 채웁니다. 삭제하시겠습니까?") ) {
        return;
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/history/delete', {
        email: this.user.data.email,
        date: this.today_date,
      }, {
        headers: {'id_token': id_token},
      })

      this.draw_graph()
    },
    async delete_all() {
      if ( confirm("삭제하기 전에 데이터 보관을 위해 XLSX 내보내기를 실행합니다. 저장하시겠습니까?") ) {
        this.download_excel()
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/history/delall', {
        email: this.user.data.email,
      }, {
        headers: {'id_token': id_token},
      })

      this.draw_graph()
    },
    async set_linestart() {

    },
    async set_predictstart() {

    },
  }
}
</script>

<style scoped>

canvas {
  height: 300px;
  min-height: 300px;
  max-height: 300px;
  width:100%;
  min-width: 100%;
  max-width: 100%;
  margin:10px;
  padding-right:20px;
}

canvas.mobile {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  width:100%;
  min-width: 100%;
  max-width: 100%;
  margin:10px;
  padding-right:20px;
}

</style>
