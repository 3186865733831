<template>
  <div>
    <div  v-if="!userloading && user.login">

      <v-tabs 
        v-model="tab" 
        :vertical="!mobile" 
        show-arrows 
        class="fixed" background-color="#141E30" color="white" style="min-height:calc(100vh - 48px)">
        <v-tab key="basic" to="#basic">기본</v-tab>
        <v-tab key="activity" to="#activity">활동관리</v-tab>
        <v-tab key="food" to="#food">식단관리</v-tab>
        <v-tab key="weight" to="#weight">체중추적</v-tab>

        <v-tabs-items touchless v-model="tab">
          <v-tab-item
            id="basic"
            key="basic"
            :transition="false"
            :reverse-transition="false">
            <Basic />
          </v-tab-item>

          <v-tab-item
            id="activity"
            key="activity"
            :transition="false"
            :reverse-transition="false">
            <Activity />
          </v-tab-item>

          <v-tab-item
            id="food"
            key="food"
            :transition="false"
            :reverse-transition="false">
            <Macro />
          </v-tab-item>

          <v-tab-item
            id="weight"
            key="weight"
            :transition="false"
            :reverse-transition="false">
            <Graph />
          </v-tab-item>

        </v-tabs-items>

      </v-tabs>

    </div>
    <div v-if="!userloading && !user.login" >
      <NeedLogin />
    </div>
    <div v-if="userloading">
      <UserLoading />
    </div>
  </div>

</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../config/config.json';
import { sleep, calc_food, calc_protein } from '../common/functions.js';

import { mapGetters } from 'vuex';

import NeedLogin from './User/NeedLogin';
import UserLoading from './User/UserLoading';
import Basic from './Main/Basic';
import Macro from './Main/Macro';
import Activity from './Main/Activity';
import Graph from './Weight/Graph';

// import NeedLogin from './User/NeedLogin';

export default {
  components: {
    NeedLogin,
    UserLoading,
    Basic,
    Macro,
    Graph,

    Activity,
  },
  props: {
    msg: String
  },
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      data: "data",
      lvl: "lvl",
      userloading: "userloading",
    })
  },
  data: function() {
    return {
      tab: "basic",

    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>